export const locale = {
  GENERAL: {            
    CANCEL: '取消',      
    CONFIRM: '确认',
    EDIT:'编辑',
    ADD:'新增',
    COPY:'复制',
    DELETE:'删除',
    SAVE:'储存',
    SHOW_CONTENT: '展开',
    HIDE_CONTENT: '收合',
    AUTH:'无权查看此物件内容',
    DAY:'天',
    ALL:'全部',    
    RELEASE:'发布',
    RESTART_EDITING:'重启编辑',
    CHANGE_OWNER:'变更拥有者',
    CHANGE_OWNER_2:'变更客服人员',
    CHANGE_OWNER_3:'变更作者',
    ADD_DEAL:'新增商机',
    ADD_Case:'新增案件',
    CLOSE:'关闭',
    PIN:'钉选',
    FIELDHISTORY:'变更纪录',
    NO_FIELDHISTORY:'尚无变更纪录',
    NO_EMAIL:'尚无电邮',
    NO_SMS:'尚无简讯',
    ACTIVE:'执行',
    LOGOUT:'登出',
    SUCCESS:'成功',
    DASHBOARD: '仪表板',
    DATASHEET:'资料表',
    NEXT:'下一步',
    PRE:'上一步',
    NONE:'无',
    MAIL:'电子邮件',
    LAST_NAME: '姓氏',
    FIRST_NAME: '名称',
    SHORTCUTS: '捷径',
    STATUS: '状态设定',
    REFRESH: '更新',
    SETTING_BTN:'资料表&筛选',
    PRODUCT:'产品',
    ACTIVITY:'活动',
    TO:'收件者',
    CC:'副本',
    SENDER:'寄件者',
    INCOMING:"收件",
    OUTGOING:"寄件",
    RELOAD: '重整',
    LOGOUT_MSG:"您确定要登出吗?",
    SEARCH:"搜寻",
    UPDATE:"更新",
  },
  //------- Log in
  LOGIN:{
    ACCOUNT:'帐号',
    PWD:'密码',
    TENANT:'租户名称',
    TRY:'免费试用',
    LOGIN:'登入',
    LOGIN_2:'登入 UpDay',
    LOGIN_UPCHAT:'登入 UpChat',
    LOGIN_UPGPT:'登入 UpGPT',
    GET_VALI_CODE: '取验证码',
    LOGIN_VALI_CODE: '验证码',
    DESCRIPTION:'一站式轻松管理所有客户资讯',
    PASSWORD:'忘记密码',
    ERROR:'请输入帐号密码',
    ERROR_2:'请输入租户名称',
    ERROR_3:'帐号或密码错误，请重试',
    ERROR_3_VALI_CODE:'帐号或密码或验证码错误，请重试', 
    ERROR_4:'请输入帐号取得验证码',
    SENT_VALI_CODE:'验证码已发送至您的电邮',
    LOGIN_VAL_ERROR:'无法发送验证码。详情请联系系统管理员',
  },
  FORGET_PASSWORD:{
    TITLE:'忘记密码',
    TENANT:'租户名称',
    MAIL:'E-Mail',
    ERROR:'输入资料有误，请确认后再试一次。',
  },
  TRY_IT_FREE:{
    TITLE:'免费试用 UpDay 14天',
    FIRST_NAME:'*名称',
    LAST_NAME:'*姓氏',
    ORG_NAME:'公司名称',
    ROLE:'职称',
    PHONE:'联络电话',  
    MESSAGE:'信息',   
    MESSAGE_2:'谢谢您申请，我们将尽快与您联系',
    ERROR:'请输入有效的电子邮件', 
  },
  //------- Bower Title
  Bower_Title:{
    UPCHAT:'Upday CIC - 客户互动中心',
    Oppty_Dashboard: '商机案件仪表板 - UpDay',
    Case_Dashboard: '客服案件仪表板 - UpDay',
    Activity: '活动',
    Oppty_List:'商机总览',
    Oppty:'商机',
    Deal:'商机',
    Contacts_List:'联络人总览',
    Contact:'联络人',
    Company_List:'公司总览',
    Company:'公司',
    Account: '公司',
    Campaign_List:'活动总览 - UpDay',
    Campaign:'行销活动',
    Segment_List:'分群总览 - UpDay',
    Segment:'分群',
    Case_List:'案件总览',
    Case:'案件',
    KnowledgeArticle_List:'知识库文章总览 - UpDay',
    KnowledgeArticle:'知识库文章',
    Setting:'设定 - UpDay',
    Login:'登入UpDay',
    ResetPasswordogin:'重设密码 - UpDay',
    PermissionError:'权限不足 - UpDay',
    PermissionError_PAGE:'无权查看此物件内容',
    PageNotFoundError:'此物件不存在 - UpDay',
    PageNotFoundError_PAGE:'此物件不存在，请返回上一页。',
  },
  //---------Rule
  RULE:{
    RULE_R_NUM:'此栏位为必填，请填写数字',
    RULE_R_NUM_2:'请填写17位内的数字(可包含小数点后两位)',
    RULE_R_NUM_3:'请填写小数点后两位的数字',
    RULE_R_NUM_4: '请填写17位内的数字',
    RULE_R_NUM_5: '请填写正数',
    RULE_R_NUM_6: '请填写1~2的整数',
    RULE_NUM:'请填写数字',
    RULE_R:'此栏位为必填',
    RULE_R_LASTNAME:'联络人姓氏为必填',
    RULE_R_LASTNAME_2:'姓氏为必填',
    RULE_R_LASTNAME_3:'使用者姓氏为必填',
    RULE_R_FIRSTNAME: '名称为必填',
    RULE_R_COMPANY_NAME:'公司名称为必填',
    RULE_R_OPPTY_NAME:'商机名称为必填',
    RULE_R_OPPTY_Contact:'客户名称为必填',
    RULE_R_Case_Contact:'客户名称为必填',
    RULE_R_TEMPLATE_NAME:'范本名称为必填',
    RULE_R_Case:'案件名称为必填',
    RULE_R_SEGMENT:'分群名称为必填',
    RULE_R_CAMPAIGN:'活动名称为必填',
    RULE_R_ACCOUNT:'联络人帐号为必填',
    RULE_R_PROCESS:'流程名称为必填',
    RULE_R_STAGE:'阶段名称为必填',
    RULE_R_TITLE:'标题为必填',
    RULE_R_NAME:'名称为必填',
    RULE_HTTP:'开头请填写 http://或 https://',
    RULE_URL:'请填写正确URL格式',
    RULE_EMAIL:'请填写正确E-mail格式',
    RULE_PHONE:'请填写正确电话号码格式',
    RULE_PHOTO:'请上传图档',      
    RULE_CSV:'请上传CSV档',
    RULE_FILE:'附件档案总共不能超过20MB',
    RULE_FILE_2:'照片大小请小于1MB',
    RULE_FILE_3:'档案大小请小于20MB',
    RULE_FILE_4:'照片大小请小于20MB',
    RULE_DUE_DATE:'预期结案日期为必填',      
    RULE_PROCCESS:'交易流程为必选',
    RULE_FORECAST:'预测类别为必选',
    RULE_REASON:'失败原因为必选',
    RULE_NOTE:'备注内容为必填',
    RULE_NOTE_1000:'备注内容不可超过1000字',
    RULE_DATE:'请填选正确日期格式 YYYY-MM-DD',
    RULE_DATETIME:'请填选正确格式 YYYY-MM-DD HH:mm',
    RULE_5:'字数请限制在5字内', 
    RULE_9:'字数请限制在9字内', 
    RULE_10:'字数请限制在10字内',
    RULE_16:'字数请限制在16字内', 
    RULE_17:'字数请限制在17字内', 
    RULE_20:'字数请限制在20字内',
    RULE_30:'字数请限制在30字内',
    RULE_50:'字数请限制在50字内',
    RULE_10_50:'字数请限制在10~50字内',
    RULE_60:'字数请限制在60字内',
    RULE_70:'字数请限制在70字内',
    RULE_86:'字数请限制在86字内',    
    RULE_90:'字数请限制在90字内',  
    RULE_100:'字数请限制在100字内',
    RULE_200:'字数请限制在200字内',
    RULE_250:'字数请限制在250字内',
    RULE_300:'字数请限制在300字内',
    RULE_500:'字数请限制在500字内',
    RULE_1000:'字数请限制在1000字内',
    RULE_NULL: '请输入文字',
    RULE_HHMM: '格式应为HH:MM',
    RULE_PROGRESS: '请填入0-100',
    RULE_CF_1:"选项不可重复",
    RULE_CF_2:'选项不可包含","',
  },
  BREADCRUMBS:{
    Contacts:'联络人',
    Companies:'公司',
    Deals:'商机',
    Case:'客服案件',
    Campaign:'行销活动',
    Segment:'客户分群',
    KnowledgeArticles:'知识库文章',
  },
  //-----DASHBOARD
  DASHBOARD:{
    TITLE: '商机案件仪表板',
    EXPECTED_REVENUE:'预期营收',
    SUCCESSFULLY_CLOSED:'成功结案',
    CURRENT_PERIOD:'本期结算',
    EMAIL_PERFORMANCE:'邮件成效',
    STAGE_AMOUNT:'各阶段金额',
    SALES_RANKING:'销售排行',
    LATEST_OPPTY:'最新商机',
    WITHIN_30_DAYS:'30天内',
    BEST_OPPTY:'最佳商机',
    TOP_5:'前五名',
    TODAY: '今天',
    THIS_YEAR:'今年',
    THIS_SEASON:'本季',
    THIS_MONTH:'本月',
    THIS_WEEK:'本周',
    SELECT_DATE:'选择日期',
    //----Headers
    OPPTY_OWNER:'商机拥有者',
    CONTACT_NAME:'客户名称',
    OPPTY_NAME:'商机名称',
    CURRENT_STAGE:'目前阶段',
    CREATED_TIME:'建立时间',
    AMOUNT:'金额',
    EXPECTED_CLOSE_DATE:'预期结案日期',
    THIS_PERIOD:'本期',
    PREVIOUS:'前期',
    DIFFERENCE:'相差',
    QUANTITY_SENT:'已寄出数量',
    READ:'已阅读数量',
    CLICKED:'已点击邮件连结',
    UNKNOWN:'不明',
    GO_OPPTY:'前往商机'
  },
  //------- CONTACT
  CONTACT: {
    TITLE: '联络人',      
    BULK:'推播',
    SEGMENT:'建立客户分群',
    EMAIL_PUSH:'Email推播',
    SMS_PUSH:'SMS推播',
    MESSAGE_DELETE:'您确定要删除此联络人吗？',
    MESSAGE_FIRST:'请先删除', 
    MESSAGE_OPPTY:'相关商机', 
    MESSAGE_OPPTY_2:'相关商机',
    MESSAGE_CASE:'相关案件', 
    MESSAGE_CASE_2:'相关案件', 
    MESSAGE_ALL:'请先删除相关商机或案件',
    MESSAGE_ERROR:'此联络人有商机正在进行，请指派他人后再删除',
  },
  ADDSTATICSEGMENT:{
    TITLE: '新增静态客户分群',    
    NAME:'客户分群名称',
  },
  CONTACT_DETAIL: {      
    TITLE: '职称',      
    ORG_NAME:'公司名称',
    OWNER:'联络人拥有者',
    CONTACT_INFORMATION:'联络人资讯',
    FIRST_NAME:'名称',
    LAST_NAME:'姓氏',
    MOBILE_PHONE:'行动电话',
    OFFICE_PHONE:'联络电话',
    BIRTHDAY:'生日',
    ADDRESS:'地址',
    CITY:'城市',
    COUNTRY:'国家',
    LEVEL:'级别',
    ZIP_CODE:'邮递区号',
    DEPARTMENT:'部门',
    PROVINCE:'省份',
    RELATED_CAMPAIGN:'相关活动',
    DESCRIPTION:'描述',      
    COMPANY_INFORMATION:'公司资讯',
    ADD_COMPANY_INFORMATION:'新增公司资讯',
    NO_COMPANY_INFORMATION:'尚无公司资讯',     
    COMPANY_NAME:'公司名称',
    JURISTIC_PERSON_NAME:'法人名称',
    POSITION:'职位',
    SHAREHOLDING:'持股',
    FAX:'传真',
    //----NOTE
    ADD_NOTE:'新增备注',
    NO_NOTE:'尚无备注',
    //----Oppyt
    NO_DEAL:'尚无商机', 
    //----Timeline   
    TIMELINE:'时间轴',
    NO_TIMELINE:'尚无时间轴',
    TIMELINE_TITLE:'职称已更改为',
    TIMELINE_DEPARTMENT:'部门已更改为',
    TIMELINE_LEVEL:'级别已更改为',
    TIMELINE_OWNER:'联络人拥有者已更改为',
    //----TAB
    DETAIL:'详细资料',
    NOTE:'备注',
    EMAIL:'电邮',
    SMS:'简讯',
    UPCHAT:'历史讯息',
    ALL_OPPORTUNITIES:'所有商机',
    ALL_CASES:'所有案件',
    DEAL:'商机名称',   
    STAGE:'目前阶段', 
    //----MESSAGE
    MESSAGE:'您确定要删除此备注吗？',
    MESSAGE_2:'您确定要删除此商机吗？',
    MESSAGE_3:'您确定要删除此联络人吗？',
    MESSAGE_4:'您确定要复制此联络人吗？',
    MESSAGE_5:'您确定要删除此联络人的公司资讯吗？',
    ERROR:'请选择图档',
    ERROR_2:'照片请小于5MB',
    ERROR_3:'请输入正确格式',
    ERROR_4:'未输入公司名称',
    ERROR_5:'已有此间公司',
    ERROR_6_1:'此联络人为商机',
    ERROR_6_2:'之客户，请重新指派客户后再行删除',
    ERROR_7:'此联络人相关商机正在进行，请重新指派再删除',
    ERROR_8:'此联络人无公司资讯',
  },
  ADD_CONTACT: {
    TITLE: '新增联络人',
    FIRST_NAME:'联络人名称',
    LAST_NAME:'联络人姓氏',
    SELECT_LABEL:'地区',
    MOBILE_PHONE:'行动电话',
    OFFICE_PHONE:'办公室电话',
    LEVEL:'级别',
    ORG_NAME:'公司名称',
    IMAGE:'更新联络人照片',      
  },
  EDIT_CONTACT: {
    TITLE: '修改联络人',
    FIRST_NAME:'联络人名称',
    LAST_NAME:'联络人姓氏',
    SELECT_LABEL:'地区',
    MOBILE_PHONE:'行动电话',
    OFFICE_PHONE:'办公室电话',
    LEVEL:'级别',
    ORG_NAME:'公司名称',
    IMAGE:'更新联络人照片',
  },
  ADDCONTACTORG:{
    TITLE:'新增公司资讯',
    COMPANY_NAME:'公司名称',
    JURISTIC_PERSON_NAME:'法人名称',
    POSITION:'职位',
    SHAREHOLDING:'持股',
    ERROR:'已有此间公司',
  },
  //------- COMPANY
  COMPANY:{
    TITLE: '公司', 
    //----MESSAGE
    MESSAGE:'您确定要删除此公司吗？',
    MESSAGE_2_1:'请先删除',
    MESSAGE_2_2:'相关商机',
    MESSAGE_2_3:'相关案件',
    MESSAGE_3:'请先删除相关商机或案件',
  },
  COMPANY_DETAIL:{
    PHONE:'电话',
    ADDRESS:'地址',
    WEB:'网站',
    OWNER:'拥有者',
    INFO:'公司资讯',
    NAME:'公司名称',
    COMPANY_NUMBER:'统一编号',
    FAX:'传真',
    TYPE:'类型',
    INDUSTRY:'行业别',
    EMPLOYEE:'员工人数',
    ANNUAL_REVENUE:'营业额',
    REGISTERED_CAPITAL:'资本额',
    ESTABLISH_DATE:'成立日期',
    RELATED_CAMPAIGN:'相关活动',
    DESCRIPTION:'描述',
    CITY:'城市',
    COUNTRY:'国家',
    LEVEL:'级别',
    ZIP_CODE:'邮递区号',      
    PROVINCE:'省份',
    //----NOTE
    ADD_NOTE:'新增备注',
    NO_NOTE:'尚无备注',
    //----Oppyt
    NO_DEAL:'尚无商机',       
    STAGE:'目前阶段',
    //----primary contact
    CONTACT:'主要联络人',
    NO_CONTACT:'尚无主要联络人',
    //----Timeline   
    TIMELINE:'时间轴',
    NO_TIMELINE:'尚无时间轴',          
    TIMELINE_EMPLOYEE:'员工人数已更改为',
    TIMELINE_REVENUE:'年度营业额已更改为 $',
    TIMELINE_CAPITAL:'公司资本额已更改为 $',
    TIMELINE_OWNER:'联络人拥有者已更改为',
    TIMELINE_PEOPLE:'人',
    //----TAB
    DETAIL:'详细资料',
    NOTE:'备注',      
    ALL_OPPORTUNITIES:'所有商机',
    ALL_CASES:'所有案件',
    DEAL:'商机名称',
    //----MESSAGE
    MESSAGE:'您确定要删除此备注吗？',
    MESSAGE_2:'您确定要删除此商机吗？',
    MESSAGE_3:'您确定要删除此公司吗？',      
    ERROR:'请选择图档',
    ERROR_2:'照片请小于5MB',      
  },
  ADD_COMPANY: {
    TITLE: '新增公司',
    ORG_NAME:'公司名称',
    TYPE:'类型',
    INDUSTRY:'行业别',
    IMAGE:'上传客户LOGO',
    WEB:'网站',
    PHONE:'电话',
    FAX:'传真',
    ADDRESS:'地址',
    EMPLOYEE:'员工人数',      
  },
  Edit_COMPANY: {
    TITLE: '修改公司',
    ORG_NAME:'公司名称',
    TYPE:'类型',
    INDUSTRY:'行业别',
    IMAGE:'上传客户LOGO',
    WEB:'网站',
    PHONE:'电话',
    FAX:'传真',
    ADDRESS:'地址',
    EMPLOYEE:'员工人数',      
  },
  ADDMAINCONTACT:{
    TITLE: '新增公司联络人',
    TITLE_2: '修改公司联络人',
    NEW_CONTACT:'建立全新联络人',
    OLD_CONTACT:'选择已有联络人',
    CONTACT_NAME:'联络人姓名',
    FIRST_NAME:'联络人名称',
    LAST_NAME:'联络人姓氏',
    JURISTIC_PERSON_NAME:'法人名称',
    POSITION:'职位',
    SHAREHOLDING:'持股',
    PRIMARY_CONTACT:'主要联络人',
    ERROR:'如需新增全新联络人，请点选上方＂新增全新联络人＂按钮。如无此需求，请选择已建立之联络人',
  },
  MAINCONTACTS:{
    TITLE: '公司联络人',
    PRIMARY:'主要',
    CONTACT_NAME:'联络人姓名',
    JURISTIC_PERSON_NAME:'法人名称',
    POSITION:'职位',
    SHAREHOLDING:'持股',
    PHONE:'电话',
    MAIL:'电子邮件',
    ACTIVE:'执行',
    MESSAGE:'您确定要删除此联络人吗？',
  },
  //------- DEAL
  DEAL:{
    TITLE: '商机',
    OPPTIES:'商机',
    NO_DEAL:'尚无商机',
    NO_DEAL_STAGE:'尚无商业流程',
    PROCESS: '商业流程',
    //----MESSAGE
    MESSAGE:'您确定要删除此商机吗？',
    ERROR:'请先建立商业流程',
    DELETE_ERROR:'以下商机已结案，不可删除。',
  },
  DEAL_DETAIL:{
    CHANGE_STAGE:'变更商业流程',
    REOPEN:'重启商机',
    NAME: '商机名称',
    EXPECTED_CLOSE_DATE:'预期结案日期',
    CREATED_ON: '建立日期',
    STATUS: '状态',
    AMOUNT:'金额',
    SCORE:'分数',
    PROGRESS:'达成率',
    OWNER:'商机拥有者',
    CLIENT_NAME:'客户名称',
    NEW_CLIENT:'建立全新客户',
    CONTACT:'主要联络人',
    OPPTY_CONTACT:'商机联络人',
    NO_OPPTY_CONTACT:'尚无主要联络人',
    CLIENT_BUDGET:'客户预算',
    FORECAST_CATEGORY:'预测类别',
    RELATED_CAMPAIGN:'相关活动',
    CUSTOMER_NEEDS:'客户需求',
     //----Timeline   
     TIMELINE:'时间轴',
     NO_TIMELINE:'尚无时间轴',
     NO_AMOUNT_CHANGE:'尚无金钱变更',
     NO_STAGE_CHANGE:'尚无阶段变更',
     NO_FIELD_CHANGE:'尚无其他变更',
     NO_ACTIVITY_CHANGE:'尚无活动纪录',
     NO_MAIL:'尚无电邮',
     NO_SMS:'尚无简讯', 
     NO_NOTE:'尚无备注',
     TIMELINE_AMOUNT:'商机金额已更改为 $',
     TIMELINE_NAME:'商机名称已更改为',
     TIMELINE_FORECAST:'预测类别已更改为',
     TIMELINE_PROCESS:'商机流程已更改为',
     TIMELINE_OWNER:'联络人拥有者已更改为',
     TIMELINE_ECD:'商机预期结案日已更改为',
     SOON_DUE: "即将于",
     SOON_DUE_1:'天后到期',
     SOON_DUE_2:"已过期超过",
     SOON_DUE_3:"天",
     SOON_DUE_4:"今天到期",
    //----TAB      
    DETAIL:'详细资料',
    NOTE:'备注',
    EMAIL:'电邮',
    SMS:'简讯',      
    ALL_CASES:'所有案件',
    //----timeLineType
    TIMELINE_TAB_ALL:'全部',
    TIMELINE_TAB_AMOUNT:'金钱变更',
    TIMELINE_TAB_STAGE:'阶段变更',
    TIMELINE_TAB_FIELD:'其他变更',
    TIMELINE_TAB_MAIL:'电邮',
    TIMELINE_TAB_SMS:'简讯',
    TIMELINE_TAB_NOTE:'备注',
    //----MESSAGE
    MESSAGE:'您确定要删除此备注吗？',
    MESSAGE_2:'您确定要删除此商机吗？',
    MESSAGE_3:'结案日期将会被覆盖，请问是否仍要重启？',      
  },
  ADD_DEAL:{
    TITLE: '新增商机',
    NAME: '商机标题',
    ORG:'选择公司',
    CONTACT:'选择联络人',
    NO_CONTACT:'请输入联络人姓名进行搜寻',
    NEW_CONTACT:'建立全新联络人',
    OLD_CONTACT:'选择已有联络人',
    AMOUNT:'商机金额',
    PROCESS:'请选择商业流程',
    EXPECTED_CLOSE_DATE:'预期结案日期',
    FORCAST:'预测类别',
    DEAL_PROCESS:'商业流程',
    ERROR:'必须选择已建立的交易流程',
    ERROR_2:'必须选择已建立的联络人或点击全新联络人',
    ERROR_3:'客户与公司须至少填其一',
  },
  TO_DEAL:{
    NAME: '客户名称',
    EXPECTED_CLOSE_DATE:'预期结案日期',
    AMOUNT:'金额',
    SCORE:'分数',
    PROGRESS:'达成率',
    OWNER:'商机拥有者',
    INFO:'基本资料',
    GUIDE:'成功指引',
    BUDGET:'确认预算',
    PROBABILITY:'可能性',
    DESCRIPTION:'描述',
    DETAIL_PAGE:'前往页面',
    IN:'于',
    LOST:'失败',
    CLOSED:'结案',
    WIN:'成功结案',
    BECAUSE:'因'
  },
  RELATEDCONTACTS:{
    TITLE:'联络人',
    NEW_CONTACT:'建立全新联络人',
    OLD_CONTACT:'选择已有联络人',
    FIRST_NAME:'联络人名称',
    LAST_NAME:'联络人姓氏',
    NO_CONTACT:'请输入联络人姓名进行搜寻',
    ADD_CONTACT:'新增联络人',
    CONTACT_NAME:'联络人姓名',
    ORG_NAME:'公司名称',      
    PHONE:'电话',
    MAIL:'电子邮件',
    ACTIVE:'执行',
    MESSAGE:'您确定要删除此联络人吗？',
    ERROR:'如需新增全新联络人，请点选上方＂新增全新联络人＂按钮。如无此需求，请选择已建立之联络人',
  },
  CHILD_CASE:{
    TITLE:'子案件',
    SUBJECT: '主题',
    CONTACT:'联络人',
    PRIORITY:'优先等级',
    STATUS:'状态',
    GROUP:'组别',
    TYPE:'类型',
    OWNER:'客服人员',
    CLOSED_DATE:'结案日期',
    ACTIVE:'执行',
    ADD_CHILD_CASE:'新增子案件',
    NO_CHILD_CASE: '无符合搜寻字词的结果',
    SEARCH_CHILD_CASE: '请输入案件标题进行搜寻',
    MESSAGE:'您确定要删除此子案件吗？',
    ERROR: '请选择已建立之案件'
  },
  EDITDEALDATE:{
    TITLE:'修改预期结案日',
    NAME:'商机名称',
    EXPECTED_CLOSE_DATE:'预期结案日期',
  },
  //------- Case
  CASE:{
    TITLE: '客服案件',
    CASE:'案件',
    NO_CASE:'尚无案件',
    NO_CASE_STAGE:'尚无案件流程',
    FINISH:'结案',
    CLOSED:'关闭',
    CASE_NUMBER:'案件编号',
    OWNER:'客服人员',
    //----MESSAGE
    MESSAGE:'您确定要删除此案件吗？',
    ERROR:'请先建立案件流程',
    DELETE_ERROR:'以下案件已结案，不可删除。',
  },
  CASE_DETAIL:{      
    CHANGE_STAGE:'变更案件流程',
    CLOSED:'结案',
    REOPEN:'重启案件', 
    IN:'于',
    CONTACT:'主要联络人',
    PHONE:'进线电话',
    EMAIL:'进线电邮',
    EMAIL_2:'电邮',
    EXPECTED_CLOSE_DATE:'预期结案日期',
    CREATED_ON:'建立日期',
    PRIORITY:'优先等级',      
    STATUS:'状态',
    OWNER:'客服人员',      
    GUIDE:'客服指引',
    DAY:'天',
    CASE_NAME:'案件标题',
    CASE_NUMBER:'案件编号',
    ORG_NAME:'公司名称',
    INCOMING_CONTACT:'进线联络人',
    SOURCE:'案件来源',
    GROUP:'案件组别',
    TYPE:'案件类型',
    CASE_STATUS:'案件状态',
    RELATED_CAMPAIGN:'相关活动',
    DESCRIPTION:'案件描述',
    DESCRIPTION_2:'案件处理',
    PARENT_CASE:'母案件',
    //----TAB
    DETAIL:'详细资料',
    NOTE:'备注',      
    SMS:'简讯',   
    PRO:"产品",
    RELATED_ARTICLE:'相关文章',   
    ALL_ARTICLE:'所有文章',
    STAGE_CHANGE:'阶段变更',
    STATUS_CHANGE:'状态变更',
    FIELD_CHANGE:'其他变更',
    SUBJECT:'标题',
    CLOSED_DATE:'结案日期',
    //----Timeline   
    TIMELINE:'时间轴',
    NO_TIMELINE:'尚无时间轴',
    NO_STAGE_CHANGE:'尚无阶段变更',
    NO_STATUS_CHANGE:'尚无状态变更',
    NO_FIELD_CHANGE:'尚无其他变更',
    NO_NOTE:'尚无备注',
    NO_MAIL:'尚无电邮',
    NO_SMS:'尚无简讯',
    ADD_NOTE:'新增备注',
    ADD_PRO:'新增产品',
    DEL_PRO:'全部删除',
    CASE_CLOSED:'案件已关闭',
    TIMELINE_OWNER:'联络人拥有者已更改为',
    TIMELINE_NAME:'案件名称已更改为',
    TIMELINE_CONTACT:'主要联络人已更改为',      
    TIMELINE_STATUS:'案件状态已更改为',
    TIMELINE_PRIORITY:'优先等级已更改为',
    TIMELINE_GROUP:'所属部门已更改为',      
    TIMELINE_TYPE:'案件类型已更改为',
    TIMELINE_DUE_DATE:'预期结案日已更改为',
    TIMELINE_CASE_PROCESS:"案件处理流程已更改为",
    //----Right
    PRIMARY_CONTACT_CASE:'主要联络人相关案件',
    NO_RELATED_CASE:'尚无其他相关案件',
    RELATED_CONTACT:'案件相关联络人',
    NO_RELATED_CONTACT:'尚无相关联络人',
    CHILD_CASE:'子案件',
    NO_CHILD_CASE:'尚无相关子案件',
    KNOWLEDGE_ARTICLE:'知识库文章',
    NO_RELATED_ARTICLE:'尚无相关文章',
    EXPAND:'展开',
    COLLAPSE:'收起',
    REMOVE:'移除',
    ADD:'添加',
    //----MESSAGE
    MESSAGE:'您确定要删除此备注吗？',      
    MESSAGE_2:'您确定要删除此案件吗？',
    MESSAGE_3:'结案日期将会被覆盖，请问是否仍要重启？',      
    MESSAGE_4:'是否要与所有子案件一同结案？',      
    ERROR:'请输入正确格式',
  },
  ADD_CASE:{
    TITLE: '新增案件',
    NAME: '案件标题',
    ORG:'选择公司',
    CONTACT:'选择联络人',
    NO_CONTACT:'请输入联络人姓名进行搜寻',
    NEW_CONTACT:'建立全新联络人',
    OLD_CONTACT:'选择已有联络人',
    PHONE:'进线电话',
    EMAIL:'进线电邮',
    SOURCR:'案件来源',
    GROUP:'案件组别',
    TYPE:'案件类型',
    PARENT_CASE:'母案件',
    CATEGORY:'案件分类',
    EXPECTED_CLOSE_DATE:'预计结案日期',
    SELECT:'请选择案件处理流程',
    PROCESS:'案件流程',
    ERROR:'必须选择已建立的交易流程',
    ERROR_2:'必须选择已建立的联络人或点击全新联络人',
    ERROR_3:'客户与公司须至少填其一',
    ERROR_4:'请选择已建立之案件',
    USE_AUTONUMBER:"自动填入流水号",
  },
  TO_CASE:{
    NAME: '客户名称',
    EXPECTED_CLOSE_DATE:'预期结案日期',
    PRIORITY:'优先等级',
    SCORE:'分数',
    STATUS:'状态',
    OWNER:'客服人员',      
    GUIDE:'客服指引',
    BUDGET:'确认预算',      
    DETAIL_PAGE:'前往页面',
    IN:'于',
    LOST:'失败',
    CLOSED:'结案',
    WIN:'成功结案',
    SOLVE:'解决'
   },
   ADD_CASE_PRODUCT:{
    TITLE:'产品列表',
   },
   //------- Bulk Message
   Bulk_Message:{
    TITLE: '推播讯息',      
  },
   //------- Segment
   SEGMENT:{
    TITLE: '客户分群',      
    //----MESSAGE
    MESSAGE:'您确定要删除此分群吗？',
    MESSAGE_2_1:'请先删除',
    MESSAGE_2_2:'相关活动',
    MESSAGE_3:'请先删除相关活动',
    //----head
    NAME:'客户分群名称',
    TYPE:'分群类型',
    PEOPLE:'分群人数',
    CAMPAIGNS:'相关行销活动总数',
    OWNER:'拥有者',
    ACTIVE:'执行',
  },
  ACTIVITY:{
    ADD:'新增活动',
    TITLE:'活动',
    PRIORITY: '优先度',
    COMPLETED:'已完成',
    MARK_COMPLETED:'标记为已完成',
    REGARDING:'相关',
    TIME_FRAME:'时间区间',
    TYPE:'种类',
    OWNER:'负责人',
    SUBJECT:'主旨',
    PROGRESS:'进度',
    REMARK:'备注',
    CONTACT:'联络人',
    ORG:'公司',
    URGENT:'紧急',
    IMPORTANT:'重要',
    MEDIUM:'一般',
    LOW:'低',
    START_TIME:'起始时间',
    DUE_TIME:'到期时间',
    START_DAY:'起始日',
    END_DAY:'到期日',
    COMPLETED_UNCOMPLETED:'未完成',
    COMPLETED_COMPLETED:'完成',
    UNCOMPLETED_ACTIVITY:'未完成活动',
    TYPE_SETTING:'活动种类',
    TYPE_NAME:'种类名称',
    TYPE_COLOR:'颜色',
    TYPE_ICON:'图示',
    ADD_TYPE:'新增活动种类',
    ADD_TYPE_BTN:'新增种类',
    MESSAGE_DELETE:'您确定要删除此活动吗？',
    MONTH:'月份',
    WEEK:'周',
    DAY:'日',
    TODAY:"今日",
    MORE:" {0} 更多",
    MONDAY:"星期一",
    TUESDAY:"星期二",
    WEDNESDAY:"星期三",
    THURSDAY:"星期四",
    FRIDAY:"星期五",
    SATURDAY:"星期六",
    SUNDAY:"星期日",
    DELETE_ERROR:'无权限删除以下活动:',
    NO_CONTACT:"找不到联络人",
    NO_ORG:"找不到公司",
    NO_REGARDING:"找不到案件或商机",
    NOT_FOUND_404:"活动不存在或已被删除",
  },
  SEGMENT_DETAIL:{
    CONTACTS:'联络人总数',      
    CAMPAIGNS:'活动总数',
    TYPE:'推播类型',
    OWNER:'客户分群拥有者',
    DESCRIPTION:'分群描述',
    INFO:'分群资讯',
    NAME:'名称',
    RELATED_CAMPAIGN:'相关活动',
    TIME:'次',
    DELETE_CONTACT:'删除联络人',
    ADD_CONTACT:'新增联络人',
    CAMPAIGN_Analysis_Chart:'活动分析图表',
    NO_DATA:'尚无资料',
    CAMPAIGN_Analysis_Statistics:'活动分析统计',
    NO_CAMPAIGN_DATA:'尚无活动资料',
    //----TAB
    SETUP:'基本资料',
    CONTACT:'联络人',
    ANALYSIS:'分析',
    C_NAME:'联络人姓名',
    C_ORG:'公司名称',
    C_PHONE:'联络电话',
    C_MAIL:'电子邮件',
    C_AMOUNT:'总金额',
    C_OWNER:'拥有者',
    C_ACTIVE:'执行',
    CAM_NAME:'行销活动名称',
    CAM_TYPE:'类型',
    CAM_TIME:'活动次数',
    S_V_S:'成功总数 vs 寄送总数',
    O_V_S:'开启总数 vs 成功总数',
    C_V_S:'点击总数 vs 成功总数',
    S_V_O:'成功总数 vs 开启总数',
    S_V_C:'成功总数 vs 点击总数',
    SUCCESS:'成功总数',
    SENT:'寄送总数',
    OPEN:'开启总数',
    CLICK:'点击总数',
    END_TIME:'最后发送时间',
    //----MESSAGE
    MESSAGE:'您确定要删除此分群吗？',
    MESSAGE_2:'您确定要删除此联络人吗？',      
    ERROR:'请先删除相关活动',
  },
  ADD_SEGMENT:{
    TITLE: '新增分群',
    NAME:'分群名称',
    TYPE:'分群类型',
    DESCRIPTION:'描述',
    MESSAGE:'新分群已建立，点选确认前往查看',
  },
  EDIT_SEGMENT:{
    TITLE: '修改分群',
    NAME:'分群名称',
    TYPE:'分群类型',
    DESCRIPTION:'描述',
  },
  ADDSEGMENTCONTACT:{
    TITLE: '分群联络人',
    ADD_CONTACT:'确认新增联络人',
    FIRST_NAME:'联络人名称',
    ORG_NAME:'公司名称',      
    PHONE:'电话',
    MAIL:'电子邮件',
  },
  //------- CAMPAIGN
  CAMPAIGN:{
    TITLE: '行销活动',  
    //----MESSAGE
    MESSAGE:'确定要删除此行销活动吗？',
    MESSAGE_2:'确定要删除这些行销活动吗？',      
    ERROR:'请先建立客户分群',
    ERROR_2:'此行销活动使用中',
    ERROR_3:'以下行销活动使用中',
    //----head
    NAME:'行销活动名称',
    CHANNEL:'推播渠道',
    SEGMENT:'客户分群',
    PEOPLE:'活动参与人数',
    OWNER:'拥有者',
    ACTIVE:'执行',
  },
  CAMPAIGN_DETAIL:{
    STATUS:'状态',
    CHANNEL:'推播渠道',
    PEOPLE:'活动参与人数',
    SEGMENT:'客户分群',
    OWNER:'行销活动拥有者',
    DESCRIPTION:'活动描述',
    NAME:'名称',
    TYPE:'活动分类',
    START_TIME:'开始日期',
    END_TIME:'结束日期',
    TEMPLATE:'选择样板',
    EMAIL_CONTENT:'Email内容：',
    SMS_CONTENT:'SMS内容：',
    PREVIEW:'预览',
    SENTING:'寄送中...',
    NO_O365:'您还未设定您的M365帐号。如果要寄送电邮，请至设定>Email同步设定M365帐号。谢谢！',
    INVALID_O365_TOKEN:'您的设置M365帐号长时间未使用。请转到设定>Email同步删除现有的M365帐号并重新设置。谢谢！',
    GO_SETTING:'前往设定',
    SUBJECT:'主旨',
    SMS_CONTENT_2:'简讯内容',
    CAMPAIGN_Analysis_Chart:'活动分析图表',
    NO_DATA:'尚无资料',
    CAMPAIGN_Analysis_Statistics:'活动分析统计',
    NO_CAMPAIGN_DATA:'尚无活动资料',
    ATTACHMENT:'附件',
    //----TAB
    CAM_NAME:'行销活动名称',      
    END_TIME_2:'最后发送时间',
    SETUP:'基本资料',      
    ANALYSIS:'分析',
    //----MESSAGE
    MESSAGE:'确定要删除此行销活动吗？',
    ERROR:'请输入Email主旨',    
    ERROR_2:'请输入内容',
    ERROR_3:'请先在客户分群新增联络人。',
  },
  ADD_CAMPAIGN:{
    TITLE: '新增行销活动',
    CHANNEL:'推播渠道',
    NAME:'活动名称',
    SEGMENT:'客户分群',
    DESCRIPTION:'活动描述',
  },
  EDIT_CAMPAIGN:{
    TITLE: '修改行销活动',
    CHANNEL:'推播渠道',
    NAME:'活动名称',
    SEGMENT:'客户分群',
    DESCRIPTION:'活动描述',
  },
  CAMPAIGNCONTACTLIST:{
    TITLE: '活动纪录联络人',
    ADD_SEG:'建立客户分群',
    Success:'成功寄送',
    unSuccess:'未成功寄送',
    Open:'已开启信件',
    unOpen:'未开启信件',
    Link:'已点击连结',
    unLink:'未点击连结',
    CONTACT_NAME:'联络人姓名',
    ORG_NAME:'公司名称',
    PHONE:'电话',
    MAIL:'电子邮件',
  },
  //------- KnowledgeArticles
  KNOWLEDGE_ARTICLE:{
    TITLE: '知识库文章',
    MESSAGE:'您确定要删除此文章吗？',   
    //----Headers
    H_TITLE:'文章标题',
    NUMBER:'知识库文章编号',
    STATUS:'状态',
    UPDATED_ON:'最后更新时间',
    AUTHOR:'作者',
    ACTIVE:'执行',
  },
  KNOWLEDGE_ARTICLE_DETAIL:{
    NUMBER:'文章编号',
    SUBJECT:'文章主题',
    STATUS:'文章状态',
    AUTHOR:'作者',
    TITLE:'标题',
    AUTONUMBER:'编号',
    SUBJECT_1:'主题',
    STATUS_2:'状态',
    KEYWORD:'关键字',
    DESCRIPTIONL:'描述',  
    AUTONUMBER_1:'文章编号将自动生成',
    //-----BREADCRUMBS   
    KNOWLEDGEARTICLES:'知识库文章',
    ADD_KNOWLEDGEARTICLES:'新增知识库文章',  
    //----TAB
    DETAIL:'详细资料',      
    //----MESSAGE
    MESSAGE:'您确定要删除此文章吗？',
    MESSAGE_2:'您确定要发布此文章吗？',
    MESSAGE_3:'您确定要重启编辑此文章吗？',
    ERROR:'请输入文章内容', 
  },
  PREFERENCES:{
    PREFERENCE:"Preference",
    ADD_PREFERENCE:"新增",
    TENANT: "租户",
    CODE:"Code",
    VALUE:"值",
    NAME:"名称",
    DESCRIPTION:"描述",
    ACTIVE: "执行",
    MESSAGE: "您确定要删除此Preference吗？",
    ERROR: "此CODE已存在。",
    ERROR1: "：格式错误并不是JSON",
    RULE_UPPERCASE: "必须是大写英文字母或底线"
  },
  //------- Setting
  SETTING:{
    TITLE: '设定',
    PERSONALSETTING:'个人化设定',
    PASSWORDANDLOGIN:'登入与安全性',
    EMAILSYNC:'Email 同步',
    EMAILTEMPLATE:'Email 寄件范本',
    SMSTEMPLATE:'SMS 寄件范本',
    MANAGEUSERS:'使用者管理',
    MANAGEGROUPS:'团队管理',
    CUSTOMFIELDS:'自定义栏位',
    TABLESETTING:'自定义资料表',
    FILTERSETTING:'自定义筛选',
    PIPELINESTAGESETTING:'商业流程设定',
    CUSTOMERSERVICESETTING:'客户服务选项',
    KNOWLEDGEARTICLESUBJECTSETTING:'知识库文章主题',
    AUTONUMBERSETTING:'流水号设定',
    PRODUCTSETTING:'产品设定',
    NOTIFICATIONSETTING: "公告栏设定",
    PERSONAL: "个人",
    COMMUNICATION: "沟通",
    COMPANY_SETTING: "公司设定",
    DATA_VIEW: "数据显示",
    CUSTOMER_SERVICE_SETTING: "客户服务设定",
    ACTIVITYTYPES: '活动种类设定',
    SMSVENDOR: 'SMS服务商设定',
    UPGPTSETTING: 'UpGPT设定',
    ASSISTANTSETTINGANDUPLOAD: 'AI助理设定',
    GPTTOOLS: '工具箱',
    UPLOADSTATUS:'汇入/上传纪录',
    TREESETTING: '树状选项设定',
    CALENDARSYNC:"行事历同步",
    CUSTOMOPPTYCLOSEDREASON: "自定义商机结案原因",
  },
  CUSTOMOPPTYCLOSEDREASON:{
    ADD:"新增原因",
    ADDREASON:"新增商机结案原因",
    NAME:"结案原因",
    DESCRIPTION:"原因描述",
    SELECTED:"启用",
    ACTIVE:"执行",
  },
  TREESETTING:{
    ADD:'新增选项',
    TS:'树状选项',
    DEL_MSG:'您确定要删除此选项吗?',
    OPEN_ALL:'全部展开',
    CLOSE_ALL:'全部收合',
    NO_OPTION:'至少须有一个选项',
    ERROR:'这选项名称已存在。',
    IS_USING:'此选项使用中，不可删除',
    NO_DATA:'请先新增树状选项',
  },
  UPGPT:{
    BASICSETTING: '基础设定',
    QAUPLOAD: 'Q&A资料上传',
    FOLDERUPLOAD: '资料夹上传',
    FILEUPLOAD: '档案上传',
    UPLOADIMG: '图片上传',
    CLOUD: '云端档案',
    BASICSETTINGSAVE: '储存',
    AIASSISTANTNAME: 'AI助理名称',
    AIASSISTANTNAME_TIP1: '设定您AI助理的名称',
    AIASSISTANTNAME_TIP2: '范例: 小安',
    AIASSISTANTROLE: 'AI助理角色',
    AIASSISTANTROLE_TIP1: '设定您AI助理所扮演的角色',
    AIASSISTANTROLE_TIP2: '范例: customer service agent (客服人员) for UpDay, a CRM production designed by 安然科技股份有限公司',
    TOPIC: '主题',
    TOPIC_TIP1: '提供AI助理一个主题，使其能更好的改写提问',
    TOPIC_TIP2: '范例: UpDay CRM',
    TOPICLIMIT: '主题限制',
    TOPICLIMIT_TIP1: '如果问题超出主题,AI助理会回到应有的主题范围回覆',
    TOPICLIMIT_TIP2: '范例: technology',
    RESETDATA: '清空现有知识',
    QAFILEUPLOADMESSAGE: 'Q&A档案需为CSV档(UTF-8)',
    FOLDERUPLOADMESSAGE: '仅接受压缩档',
    FILEUPLOADMESSAGE: '仅接受 txt、pdf、docx 及 pptx档',
    DELETEALLDATA: '你确定要重置所有既有资讯?',
    WRONGTYPEINZIP: '压缩档内只能有txt、CSV、pdf、docx、pptx、jpg、jpeg & png档',
    WRONGTYPEINCSV: 'Q&A档应为CSV档(UTF-8)',
    WRONGTYPEINFILE: '档案上传仅接受 txt、pdf、docx 及 pptx档',
    WRONGTYPEINIMAGEFILE: '图片上传仅接受 JPG、JPEG、PNG 档',
    WAIT: '请等待档案上传完成后再试一次',
    PREFERENCE_WRONG: '权限设定不完整，请联系管理者',
    AIASSISTANT_KNOWLEDGE_MANAGEMENT: 'AI助理知识管理',
    FILE_AMOUNT: '知识库档案总数: ',
    UPDATE_TIME: '最近一次上传时间: ',
    NOFILE: '知识库没有任何档案',
    UPCHAT_INBOX_SETTING: 'UpChat 收件匣设定',
    TIMEOUT_ERROR:'因数据量较多，AI助理仍在将其整理成知识库，是否前往"汇入/上传纪录"检视进度?',
    EMBEDDING_WRONG:'上传失败，请检查您的CSV档为UTF-8编码、标头(第一行)的所有栏位是否出现双引号(如"XXX")的情形，请去除后再重新上传。也请您检查您的档案是否被加密，请移除加密后在重新上传，如果还是未能上传，请联系UpDay客服人员，谢谢您!',
    UPLOAD_FAIL:'上传失败',
    FILE: '档案',
    DOWNLOAD: '下载',
    DOWNLOAD_TXT: '下载文字档',
    FILE_HAS_BEEN_DELETED: '档案已被删除，请重整页面',
    WEBSITEANALYSIS: '网站分析',
    ADD_URL: '新增网址',
    EDIT_URL: '编辑网址',
    URL: '网址',
    LEVEL: '深度',
    OVERLIMIT: '网址最多只能填写5个',
    ACTION: '执行',
    DELETE_CHECK: '你确定要删除这个网址吗?',
    START_ANALYSIS: '开始分析',
    DOWNLOAD_ZIP: '下载压缩档',
    BLANK_WEB_INFO: '您需要先进行过分析，才能下载结果',
    NO_URL:'请先填入至少一个网址',
    FILE_TABLE: '已连结知识库档案',
    NOT_CLEAN: '未正确清空，请再清空一次，若还是未能清空，请联系UpDay客服人员，谢谢您!',
    AUTO_DETECT: '自动侦测',
    REPLY_LANGUAGE: '回覆语言',
    REPLY_LANGUAGE_TIP: 'AI助理会依据所选择的语言回覆',
  },
  ACTIVITYTYPES:{
    ERROR:"此活动类型使用中，不可删除。",
    ERROR_2:"至少须有一个活动类型。",
    ERROR_3:"预设活动类型不可删除。"
  },
  SMSVENDOR:{
    VENDOR:"服务商",
    USER_NAME:"使用者名称",
    USER_PWD:"使用者密码",
    DEFAULT:"预设",
    MITAKE:"三竹Mitake",
    DOMAIN:"单笔简讯发送 API URL",
    TIP:"请参考三竹给您的API文件",
    TIP_2:"例 : https://smsapi.mitake.com.tw/api/mtk/SmSend?CharsetURL=UTF8",
  },
  PERSONALSETTING:{
    BOARD_CONTACT_SYNC:'从经济部商业司同步董监事资料为联络人',
    FIRST_NAME:'使用者名称',
    LAST_NAME:'使用者姓氏',
    LANGUAGE:'语言',
    CURRENCY:'币别',
    REGION:'地区',
    OPPTY_DEFAULT_STAGE:'商机预设流程',
    CASE_DEFAULT_STAGE:'案件预设流程',
    INBOX_NOTIFICATION_SETTINGS:'Inbox 通知设定',
    ERROR:'请选择图档',
    ERROR_2:'照片请小于5MB',
  },
  PASSWORDANDLOGIN:{
    TITLE: '变更密码',
    TITLE_2: '重设密码',
    MESSAGE:'为了您的帐户安全，请定时更改密码。并增加密码复杂程度，不要使用容易猜测的字串。例：生日、电话...等',
    ERROR:'请确认密码长度为8-30个字且包含大写小写英文字母、数字、特殊符号',
    ERROR_2:'输入密码不符合新密码，或密码格式错误',
    ERROR_3:'请填入目前密码',
    ERROR_4:'新密码和确认密码为必填',
    ERROR_5:'请重新确认输入密码',
    ERROR_6:'修改密码失败, 请重试',
    ERROR_7:'新密码和确认密码不同, 请重新输入',
    ERROR_8:'重设密码失败, 请重试.  厡因:',
    ERROR_9:'此重设密码连结已失效',
    OLD_PASSWORD:'目前密码',
    NEW_PASSWORD:'新密码',
    NEW_PASSWORD_2:'确认密码',
  },
  EMAILSYNC:{
    TEXT:'连结帐户',
    TEXT_2:'以下显示您已连结之M365帐户',
    NO_CONNECT:'尚无连结帐户',
    ADD_ACCOUNT:'新增帐户',
    MESSAGE:'您确定要删除此M365帐号设定吗？',
    ERROR:'删除失败, 请重试',
    DIALOG:'连结M365帐户',
    DIALOG_2:'点击新增按钮，将为您导至M365登入页面。连结成功后，将为您导回此设定页面。',
    DIALOG_S:'成功连结M365帐户',
    DIALOG_S2:'您已成功连结M365帐户。请在电子邮件同步部分验证您的帐户电子邮件地址。谢谢！',
    START_SYNC:"开始同步",
    SYNCING:"同步中",
    H1:"Email同步处理：",
    H1_1:"所有Email皆同步至UpDay",
    H1_2:"只有指定电邮同步至UpDay",
    H1_3:"开启",
    H1_4:"关闭",
    H2:"从何时开始同步：",
    H2_1:"今天",
    H2_2:"1个月内",
    H2_3:"3个月内",
    H2_4:"6个月内",
    H2_5:"自订",
    H3:"同步寄送与寄出的Email对象：",
    H3_1:"只有UpDay联络人",
    H3_2:"所有人",
    H4:"Email 限制设定：",
    H5:"设定黑名单Email或网域",
    H6:"黑名单Email",
    H7:"黑名单网域",
    H8:"可接受网域",
    TOO_MUCH_INBOX:"收件匣中的信件数量超过9999，请选择较少同步时间范围后再试一次。",
    TOO_MUCH_SENTBOX:"寄件匣中的信件数量超过9999，请选择较少同步时间范围后再试一次。",
  },
  EMAILTEMPLATE:{
    TEMPLATE:'范本',
    ADD_TEMPLATE:'新增范本',
    EDIT_EMAIL_TEMPLATE:'修改Email范本',
    ADD_EMAIL_TEMPLATE:'新增Email范本',
    NAME:'范本名称',
    SUBJECT:'主旨',
    //Header
    ADD_DATE:'建立时间',
    END_TIME:'最后修改时间',
    ACTIVE:'执行',
    MESSAGE:'您确定要删除吗？',
    ERROR:'请填入新增范本名称!',
  },
  SMSTEMPLATE:{
    TEMPLATE:'范本',
    ADD_TEMPLATE:'新增范本',
    EDIT_SMS_TEMPLATE:'修改SMS范本',
    ADD_SMS_TEMPLATE:'新增SMS范本',
    NAME:'范本名称',
    //Header
    ADD_DATE:'建立时间',
    END_TIME:'最后修改时间',
    ACTIVE:'执行',
    MESSAGE:'您确定要删除吗？',
  },
  MANAGEUSERS:{
    SEAT:'可用空位',
    ACTIVATED:'启用',
    UNACTIVATED:'未启用',
    INVITE_USERS:'邀请使用者',
    MANAGER:'管理者',
    STAFF:'员工',
    MAIL:'Email (将作为登入帐号使用)',
    FIRST_NAME:'使用者名称',
    LAST_NAME:'使用者姓氏',
    USER_ROLE:'使用者角色',
    INVITE:'送出邀请',
    EDIT_USER:'编辑使用者',
    //Header
    NAME:'名称',
    ROLE:'角色',
    IS_SERVICE_AGENT:'客服人员',
    LAST_TIME:'最后登入时间',
    ACTIVE:'执行',
    MESSAGE:'您确定要停用此用户吗？',
    MESSAGE_2:'您确定要重启此用户吗？',
    ERROR:'抱歉，使用者人数已达上限！如需新增，请将现有联络人设为未启用。',
    ERROR_2:'抱歉，您的权限不足！',
    ERROR_3:'帐号已存在。',
    ERROR_4: '至少需有一位管理者',
  },
  MANAGEGROUPS:{
    ADD_GROUP:'新增团队',
    GROUP:'团队',
    NAME:'名称',
    TYPE:'类型',
    MEMBER:'成员',
    //Header
    ACTIVE:'执行',
    ERROR:'抱歉，您的权限不足！',
    ERROR_2:'这团队名称已存在。',
    MESSAGE:'您确定删除要此团队吗？',
  },
  CUSTOMFIELDS:{
    SELECT_PROCESS:'选择流程',
    SELECT_PAGE:'选择页面',
    ADD_FIELD:'新增栏位',
    EDIT_OPTION:'编辑选项',
    OPTION:'选项',
    TREE_OPTION:'树状选项',
    ADD_CF:'新增自定义栏位',
    P_SELECT_PAGE:'请选择使用页面',
    SELECT_O_PROCESS:'选择商业流程',
    SELECT_C_PROCESS:'选择案件流程',
    SELECT_MESSAGE: '选择一个或多个栏位至表单',
    NAME:'栏位名称',
    TYPE:'栏位类型',
    DESCRIPTIONS: '栏位描述',
    MANDATORY:'是否必填',
    //Header
    ACTIVE:'执行',
    NO_OPPTY_STAGE:'尚无商业流程',
    NO_CASE_STAGE:'尚无案件流程',
    ERROR:'请至少保留两个选项',
    ERROR_2:'请输入正确内容',      
    ERROR_3:'请至少输入两个选项',
    ERROR_4:'请先新增商业流程',
    ERROR_5:'请先新增案件流程',
    ERROR_6:'请输入新选项内容，并按下Enter',
    MESSAGE:'您确定要删除吗？',
  },
  TABLESETTING:{
    PAGE:'选择页面',
    PROCESS:'选择流程',
    NEW:'新增资料表',
    COPY: '复制当前资料表',
    DEFAULT:'预设',
    NAME:'名称',
    MESSAGE:"您确定要删除此资料表吗？",
    SET:'设定',
    FIELD:'栏位',
    SEQ:'排序',
    TITLE:'编辑当前资料表',
  },
  FILTERSETTING:{
    FILTER:'筛选',
    ADD:"新增筛选",
    EDIT:"编辑筛选",
    COPY: "复制筛选",
    VISIBILITY:"隐私设定",
    VISIBILITY_0:"私人",
    VISIBILITY_1:"公开",
    DEFAULT: '预设',
    FILTERFIELD:"选择栏位",
    PERSONAL_SETTING: '个人设定',
    SYSTEM_SETTING: '系统设定',
    CONDITION:"选择条件",
    ADD_CONDITION:"新增条件",
    DATE:"选择日期",
    CAMPAIGN: '选择相关活动',
    SEG:'选择相关分群',
    PRODUCT:'选择产品',
    PARENT_CASE: '选择母案件',
    TEXT:"填入文字",
    NUM:"填入数字",
    OPT:"选择选项",
    TRUE:"是",
    FALSE:"否",
    INPUT_TO_SEARCH: '请输入公司名称进行搜寻',
    INPUT_CONTACT_TO_SEARCH: '请输入联络人名称进行搜寻',
    INPUT_PRODUCT_TO_SEARCH: '请输入产品名称进行搜寻',
    INPUT_PARENT_CASE_TO_SEARCH: '请输入母案件名称进行搜寻',
    ERROR: '请至少新增一项条件',
    ERROR2: '请选择栏位',
    ERROR3: '请确认搜寻条件',
    MESSAGE: '您确定要删除此筛选吗？',
    PUBLIC:'公开',
    PRIVATE:'私人',
    CS:'自定义选项',
    NO_FILTER:'无筛选',
    DELETED_DATA: '已被删除的资料',
  },
  OP_TEXT:{
    CONTAINS:"包含",
    NOT_CONTAINS:"不包含",
    IS_EMPTY:"为空",
    NOT_EMPTY:"不为空",
  },
  OP_NUM:{
    EQUAL:"等于",
    EQUAL_LESS:"小于等于",
    EQUAL_LARGER:"大于等于",
    LESS:"小于",
    LARGER:"大于",
  },
  OP_DATE:{
    ON:"等于",
    ON_OR_AFTER:"等于 或 之后",
    ON_OR_BEFORE:"等于 或 之前",
    TODAY:"今天",
    YESTERDAY:"昨天",
    TOMORROW:"明天",
    NEXT_7_DAYS:"接下来7天",
    LAST_7_DAYS:"之前7天",
    THIS_WEEK:"本周",
    THIS_MONTH:"本月",
    THIS_QUARTER:"本季",
    THIS_YEAR:"今年",
    NEXT_WEEK:"下周",
    NEXT_MONTH:"下个月",
    NEXT_QUARTER:"下一季",
    NEXT_YEAR:"明年",
    LAST_WEEK:"上周",
    LAST_MONTH:"上个月",
    LAST_QUARTER:"上一季",
    LAST_YEAR:"去年",
  },
  PIPELINESTAGESETTING:{
    ADD:'新增流程',
    WORRY:'不用担心您的团队没有按照商业流程或是SOP！只要您在此设定好商业流程，系统将会引导您的团队迈向更好的结果。',
    ERROR:'此流程已有商机使用，请更换流程后再行删除。',
    ERROR_2:'此流程已有案件使用，请更换流程后再行删除。',
    MESSAGE:'您确定要删除吗？',
    DEFAULT_PROCESS: '系统预设流程'
  },
  CUSTOMERSERVICESETTING:{
    ADD:'新增选项',
    TYPE:'案件类型',
    GROUP:'案件组别',      
    CATEGORY:'案件分类',   
    ADD_OPTION:'新增客户服务选项',
    USEING_TYPE:'请选择使用类型',
    NAME:'选项名称',
    OPTION_DESCRIPTION:'选项描述',
    //----TAB
    ACTIVE:'执行',
    MESSAGE:'您确定要删除吗？',
    ERROR_1:"此案件组别正在使用中",
    ERROR_2:"此案件类型正在使用中",
  },
  KNOWLEDGEARTICLESUBJECTSETTING:{
    ADD_SUBJECT:'新增主题',
    ADD:'新增知识库文章主题',
    NAME:'主题名称',
    DESCRIPTION:'主题描述',
    ACTIVE:'执行',
    MESSAGE:'确定要删除此主题吗？',
  },
  AUTONUMBERSETTING:{
    CASE_SETTING:'案例设定',
    PERFIX:'前缀字元',
    SUFFIX:'后缀字元',
    RECODR_TYPE:'纪录类型',
    NUMBER_FORMAT:'数字格式',    
    START_NUMBER:'起始号码',
    CURRENT_NUMBER:'目前号码',
    RESET:'重置目前号码',
    ACTIVATED:'启用',
    UNACTIVATED:'停用',
    KA_SETTING:'知识文章设定',
    TIME_MODE:'时间模式',
    NUMBER_MODE:'数字模式',
    NO_RECORD:'尚无纪录',
    MESSAGE:'目前号码小于起始号码，是否重置目前号码？',
    RULE:'字数请限制在',
    RULE_1:'字内',
    AUTO:'自动填入标题',
  },
  PRODUCTSETTING:{
    PRODUCT:'产品',
    CATEGORY:'类别',
    SERIES:'系列',
    NAME:'名称',
    PRODUCTCODE:'序号',
    PRICR:'定价',
    COST:'成本',
    DESCRIPTIONL:'描述',
    ADD_PRODUCT:'新增产品',
    EDIT_PRODUCT:'修改产品',
    ADD_PRODUCT_2:'新增产品类别',
    EDIT_PRODUCT_2:'修改产品类别',
    ADD_PRODUCT_3:'新增产品系列',
    EDIT_PRODUCT_3:'修改产品系列',
    MESSAGE:'您确定要删除吗？',
    ERROR:'此商品正在使用中',
    ERROR_2:'此类别正在使用中',
    ERROR_3:'此系列正在使用中',
  },
  NOTIFICATIONSETTING: {
    ADD_NOTICE: "新增",
    NOTICE: "公告",
    NOTICEBOARD: "公告栏",
    TITLE: "标题",
    CONTENT: "内容",
    STATUS: "状态",
    ACTIVE: "执行",
    MESSAGE: "您确定要删除此公告吗？",
    MESSAGE2: "您确定吗？下一次登入将再次显示。",
    ACTIVATED: "启用",
    UNACTIVATED: "停用",
    ERROR: "此公告标题已存在。",
    TIMER:"公告转动时间",
    SECONDTIMES:"秒/次"
  },
  INBOX_NOTIFICATION_SETTINGS: {
    ALLOW_WEB_PUSH: "允许开启网页推播",
    ALLOW_FOLLOW: "在我追踪的纪录变更时提醒我",
    ALLOW_MENTION: "在我被@提及时提醒我",
    ALLOW_REPLY: "在我订阅的贴文有人回覆时提醒我",
    ALLOW_EMOJI: "在我订阅的贴文有人喜欢时提醒我",
  },
  CALENDAR_SYNC_SETTINGS: {
    SELECT_CALENDAR: "选择行事历",
    SYNC_TYPE: "同步模式",
    SYNC_AS: "同步为",
    PRIVATE_EVENTS: "私人活动",
    SELECT_TO_SYNC: "要同步的活动类型",
    SYNC_REGARDING:"同步相关资料",
    PRIVATE_EVENTS_0:"不同步",
    PRIVATE_EVENTS_1:"同步",
    SYNC_REGARDING_0:"不包含相关资料",
    SYNC_REGARDING_1:"包含相关资料",
    NO_SELECT_TYPE:"尚未选择",
    STOP_SYNC:"停止同步",
    CONNECT_SUCCESS:"您已成功连结M365帐户。请在行事历同步页面验证您的帐户电子邮件地址。谢谢！",
    SYNC_TYPE_ONE_0:"单向同步",
    SYNC_TYPE_ONE_1:"UpDay活动同步至M365行事历",
    SYNC_TYPE_ONE_2:"仅更改UpDay活动同步至UpDay活动",
    SYNC_TYPE_TWO_0:"双向同步",
    SYNC_TYPE_TWO_1:"UpDay活动同步至M365行事历",
    SYNC_TYPE_TWO_2:"M365行事历同步至UpDay活动",
  },
  //--------Common DIALOG
  CHANGEOWNER:{
    TITLE:'变更客服人员',
    TITLE_2:'变更文章作者',
    TITLE_3:'变更拥有者',      
  },
  CASECARD:{
    TITLE:'案件',
    NO_CASE:'尚无案件',
    CASE_NUMBER:'案件编号',
  },
  PRODUCTTABLE:{
    NO_PRO:'尚无产品',
    MESSAGE:'您确定要删除此产品吗？',
  },
  PRODUCTLINEITEM:{
    QUANTITY:'数量',
    QUANTITY_H:'数量',
    QUANTITY_D:'总数量',
    DAYS:"天数",
    TAX:'税',
    TAX_H:'税',
    UNIT:'单位',
    DISCOUNT:'折扣',
    DISCOUNT_H:'折扣',
    AMOUNT:'金额',
    AMOUNT_H:'金额',
    SUBTOTAL_LIST_PRICE:'定价小计',
    TOTAL_DISCOUNT:'折扣小计',
    SUBTOTAL_AMOUNT:'金额小计',
    SALES_TAX:'销售税额',
    TOTAL:'总计 ',
    TOTAL_PRODUCTS:'商品总数',
    TAX_INCLUSIVE:'金额含税',
    TAX_EXCLUSIVE:'金额未税',
    NO_TAX:'不计税',
    SYSTEM_COMPUTE:'系统计算',
    MANUAL_INPUT:'手动输入',
    DISCOUNT_DATA:'折扣',
    TAX_DATA:'税额',
    OTHER_CHARGES:'其他费用',
    ENABLE_TRQ:'时间区间',
    FROM:'起始日期',
    TO:'结束日期',
    FROMTOERROR:'不可只填单一日期',
    OC_DISCOUNT:'其他费用小计',
    OC:'其他费用',
  },
  CASETABLE:{      
    NO_CASE:'尚无案件', 
    MESSAGE:'您确定要删除此案件吗？',
    SUBJECT:'主题', 
    CONTACT:'联络人',
    PRIORITY:'优先等级',
    STATUS:'状态',
    GROUP:'组别',
    TYPE:'类型',
    OWNER:'客服人员',
    CLOSED_DATE:'结案日期',
  },
  OPPTYCARD:{
    TITLE:'商机',
    NO_OPPTY:'尚无商机',
    EXPECTED_CLOSE_DATE:'预期结案日期',
  },
  CUSTOMFIELD:{
    TITLE:'自定义栏位',
    NO_DATA:'尚无自定义栏位',      
  },
  EMAILEDITOR:{
    RECIPIENT:'收件人',
    COPY:'副本',
    ATTACHMENT:'附件',
    SUBJECT:'主旨',
    SEND:'寄出',
    CLEAR:'清除',
    TEMPLATE:'Email 范本',
    NO_O365:'您还未设定您的M365帐号。如果要寄送电邮，请至设定>Email同步设定M365帐号。谢谢！',
    INVALID_O365_TOKEN:'您的设置M365帐号长时间未使用。请转到设定>Email同步删除现有的M365帐号并重新设置。谢谢！',
    NO_DATA: '尚无资料',
    GO_SETTING:'前往设定',
    ERROR:'附件档案总共不能超过20MB',
    ERROR_2:'此电邮地址格式不正确',
    ERROR_3:'寄送电邮之前请先填妥栏位',
  },
  SMSEDITOR:{
    ADD: '新增',
    RECIPIENT:'收件人',
    NUMBER:'收件号码',
    ENTER:'输入手机号码',
    SEND:'传送',
    CLEAR:'清除',
    TEMPLATE:'SMS 范本',
    ERROR:'寄送简讯之前请先填妥栏位',
    ERROR_2:'请输入正确格式',
  },
  LOSEREASON:{
    TITLE:'客服案件结案',
    TITLE_2:'选择失败原因',
    TITLE_3:'确认赢得商机',
    CASE_NAME:'案件名称',
    CONTACT_NAME:'主要联络人名称',
    OPPTY_NAME:'商机名称',
    CUSTOMER_NAME:'客户名称',
    CLOSE_DATE:'结案日期',
    CLOSE_REASON:'结案原因',
    STATUS:'状态',
    CLOSED:'已结案',
    OPEN:'进行中',
    AMOUNT:'金额',
    LOSE:'失败原因',
    WIN:'赢得商机',
  },
  EMAILCAMPAIGNDIALOG:{
    TITLE:'Email 预览',
    SUBJECT:'主旨',
    ATTACHMENT:'附件',
    MESSAGE:'Email寄送中，点选确认前往活动页面查看寄送进度'
  },
  SMSCAMPAIGNDIALOG:{
    TITLE:'SMS 预览',
    SMS_CONTENT:'简讯内容',
    MESSAGE:'简讯寄送中，点选确认前往活动页面查看寄送进度'
  },
  ADDPROCESSDIALOG:{
    EDIT:'编辑流程',      
    ADD_OPPTY:'新增商业流程',
    ADD_CASE:'新增案件流程',
    NAME:'流程名称',
    PAGE:'流程使用页面',
    STAGE_NAME:'阶段名称',
    PROBABILITY:'商机赢得机率',
    FINISH_DAY:'预期完成天数',
    GUIDE:'成功指引',
    COLOR:'颜色',
    DAY:'天',
    ERROR:'阶段不可为空',
    ERROR_2:'此步骤已有使用纪录，故无法删除。',
    MESSAGE:'您确定要删除步骤吗？'
  },
  ADDNOTE:{
    TITLE:'新增备注',
    TITLE_2:'修改备注',
    CONTENT:'请输入备注内容',
  },
  EMAILATTACHMENTSDIALOG:{
    TITLE:'电邮附件',
  },
  EMAILCONTACTSDIALOG:{
    TITLE:'关联联络人',
  },
  EMAILOPPTIESDIALOG:{
    TITLE:'关联商机',
    NO_OPPTY:'尚无关联商机',
    EXPECTED_CLOSE_DATE:'预期结案日期 : ',
  },
  REDIRECTDIALOG:{
    NEW_CONTACT:'查看新联络人',
    NEW_ORG:'查看新公司',
    NEW_OPPTY:'查看新商机',
    NEW_CASE:'查看新案件',
  },
  //------- List.js
  LEVELLIST:{0:"职员",1:"经理",2:"董事",3:"C-Level",4:"所有者"},
  KASTATUSLIST:{
    PUBLISHED:'已发布',
    WAIT_PUBLISH:'等待发布',
    IN_REVIEW:'审查中',
    AUTHORING:'撰写中',
  },
  CASESTATUSLIST:{
    OPEN:'进行中',
    PENDING:'待办中',
    WAIT_CUSTOMER:'等待客户',
    WAIT_3RD_PARTY:'等待第三方',
    RESOLVED:'已解决',
    CLOSED:'关闭',
  },
  OPPTYSTATUSLIST:{
    OPEN:'进行中',
    WON:'赢得商机',
    LOSE:'商机失败',
  },
  CAMPAIGNTYPELIST:{
    ADVERTISEMENT:'广告',
    DIRECT_MARKETING:'直接营销',
    MARKETING_EVENT:'事件营销',
    JOINT_MARKETING:'合作营销',
    OTHERS:'其他',
  },
  CASEPRIORITYLIST:{
    VERY_HIGH:'最高',
    HIGH:'高',
    MEDIUM:'中',
    LOW:'低',
    VERY_LOW:'最低',
  },
  CASESOURCELIST:{
    EMAIL:'电邮',
    WEB_FORM:'网路表单',
    WEB_CHAT:'线上客服',
    PHONE:'电话',      
    STORE:'实体店',
    OUTBOUND:'外拨',
    OTHERS:'其他',
  },
  CLOSECASEREASONLIST:{
    RESOLVED:'解决',
    ALTERNATIVE:'替代解决方案',
    NO_SOLUTION:'无解决方案',
    NO_RESPONSE:'客户无回应',
    NOT_AN_ISSUE:'非问题',
  },
  CLOSEREASONLIST:{
    null: '进行中商机',
    0:'重启商机',
    1:'赢得商机',
    2:'抢输竞争对手',
    3:'对方不做事',
  },   
  NUMBERTYPELIST:{
    3:'三位数',
    4:'四位数',
    5:'五位数',
    6:'六位数',
    7:'七位数',
    8:'八位数',
    9:'九位数',
    10:'十位数',
    11:'十一位数',
    12:'十二位数',
    13:'十三位数',
    14:'十四位数',
    15:'十五位数',
  },
  SEGMENT_TYPELIST:{
    STATIC:'固定分群',
    DYNAMIC:'动态分群',
  },
  STAGEDEFCOLORLIST:{
    0:'红色',
    1:'橘色',
    2:'黄色',
    3:'绿色',
    4:'蓝色',
    5:'紫色',
  },
  INDUSTRYLIST:{
    0:'农、林、渔、牧业',
    1:'矿业及土石采取业',
    2:'制造业',
    3:'电力及燃气供应业',
    4:'用水供应及污染整治业',
    5:'营建工程业',
    6:'批发及零售业',
    7:'运输及仓储业',
    8:'住宿及餐饮业',
    9:'出版影音及资通讯业',
    10:'金融及保险业',
    11:'不动产业',
    12:'专业、科学及技术服务业',
    13:'支援服务业',
    14:'公共行政及国防；强制性社会安全',
    15:'教育业',
    16:'医疗保健及社会工作服务业',
    17:'艺术、娱乐及休闲服务业',
    18:'其他服务业',
  },
  ORGTYPELIST:{
    0:'外国公司之分公司',
    1:'外国公司之办事处',
    2:'本地公司设立之分公司',
    3:'合作社',
    4:'合夥',
    5:'有限公司',
    6:'有限合夥',
    7:'两合公司',
    8:'其他',
    9:'股份有限公司',
    10:'无限公司',
    11:'独资',
  },
  AGENTSTATUSLIST:{
    ONLINE:'线上',
    AWAY:'离开',
    BUSY:'忙碌',
    OFFLINE:'离线',
  },
  CONTACTSFIELDLIST:{
    created_on:'建立时间',
    created_by:'建立者',
    updated_on:'更新时间',
    updated_by:'更新者',
    owner:'拥有者',
    orgs:'公司名称',
    full_name:'联络人姓名',
    total_amount:'进行商机总额',
    title:'职称',
    email:'电子邮件',
    mobile_phone:'行动电话',
    office_phone:'连络电话',
    description:'描述',
    department:'部门',
    birthdate:'生日',
    level:'级别',
    fax:'传真',
    facebook:'Facebook',
    linkedin:'Linkedin',
    instagram_id:'Instagram',
    line_id:'Line',
    billing_address:'地址',
    city:'城市',
    country:'国家',
    zip_code:'邮递区号',
    province:'省分',
    primary_campaign_id:'相关活动',
    seg:'分群'
  },
  ORGSFIELDLIST:{
    created_on:'建立时间',
    created_by:'建立者',
    updated_on:'更新时间',
    updated_by:'更新者',
    owner:'拥有者',
    name:'公司名称',
    website:'网站',
    company_number:'统一编号',
    main_phone:'电话',
    description:'描述',
    industry:'行业别',
    employee:'员工人数',
    establish_date:'成立日期',
    annual_revenue:'营业额',
    registered_capital:'资本额',
    fax:'传真',
    billing_address:'地址',
    city:'城市',
    country:'国家',
    zip_code:'邮递区号',
    province:'省分',
    total_amount:'进行商机总额',
    organization_type:'类型',
    primary_campaign_id:'相关活动',
  },
  OPPTYSFIELDLIST:{
    created_on:'建立时间',
    created_by:'建立者',
    updated_on:'更新时间',
    updated_by:'更新者',
    owner:'商机拥有者',
    name:'商机名称',
    amount:'金额',
    expected_close_date:'预期结案日期',
    closed_date:'结案日期',
    closed_reason:'结案原因',
    status: '状态',
    forecast_category:'预测类别',
    customer_needs:'客户需求',
    customer_budget:'客户预算',
    budget_confirmed:'确认预算',
    primary_campaign_id:'相关活动',
    stage_type:'商业流程',
    oppty_contact:'客户名称',
    current_stage:'现在阶段',
    primary_contact:'主要联络人',
    description:'描述',
    filter_oppty_contact: '客户名称（联络人）',
    filter_oppty_org: '客户名称（公司）',
    tax_option:'计税方式',
  },
  CASESFIELDLIST:{
    created_on:'建立时间',
    created_by:'建立者',
    updated_on:'更新时间',
    updated_by:'更新者',
    owner:'客服人员',
    subject:'标题',
    case_number:'案件编号',
    due_date:'预期结案日',
    incoming_fullname:'进线联络人',
    incoming_phone:'进线电话',
    incoming_email:'进线电邮',
    source:'案件来源',
    status:'状态',
    closed_date:'结案日期',
    closed_reason:'结案原因',
    priority:'优先等级',
    primary_campaign_id:'相关活动',
    primary_contact:'联络人',
    case_org:'公司',
    group:'组别',
    type:'类型',
    stage_type:'案件流程',
    current_stage:'阶段',
    description:'案件描述',
    resolution:'案件处理',
    product:'产品',
    parent_case_id: '母案件',
  },
  CASEDASHBOARD: {
    TITLE: '客服案件仪表板',
    UNSOLVED: '未解决',
    OVERDUE: '过期',
    DUE_TODAY: '今日到期',
    CLOSED: '已结案',
    OPEN: '进行中',
    ON_HOLD: '等待中',
    HIGH_PRIORITY: '高优先等级',
    AVERAGE_RESOLVED_TIME: '平均处理时间',
    DAY: '天',
    HOUR: '小时',
    MIN: '分钟',
    PRIORITY: '优先等级',
    SOURCE: '进线来源',
    GROUP: '组别',
    TYPE: '类型',
    PROCESS: '处理流程',
    LINE_CHART: '案件折线图',
    RECEIVED: '受理案件',
    RESOLVED: '解决案件',
    CASE_RANKING: '案件处理排行',
    CASES: '件',
    UP: '上升',
    DOWN: '下降',
    AGENT_STATUS: '客服人员状态',
    AGENT_TOTAL: '客服人员总数'
  },
  UPCHATDASHBOARD:{
    TITLE: '聊天互动仪表板',
  },  
  CAMPAIGNSTAUSLIST:{
    PROPOSED:'已提案',
    READY_TO_ACTIVATE:'准备启动',
    ACTIVATED:'已启动',
    COMPLETED:'已完成',
    CANCELLED:'已取消',
    SHELVED:'搁置',
    NOT_IN_USE:'非使用中',
  },
  IMPORT_EXPORT:{
    TITLE:'汇入资料',
    TITLE_2:'汇出资料',
    IMPORT:'汇入',
    EXPORT:'汇出',
    PAGE:'页面',
    DATATYPE:'汇出资料',
    STAGE:'流程',
    DOWNLOAD:'下载',
    TEMP:'范本',
    DB_DATA:'所有资料',
    SELECT:'选择档案',
    NOTICE:'注意事项',
    DOWNLOAD_TEMP:'下载汇入范本',
    ERROR:'本浏览器不支援汇出功能, 建议使用Chrome或Edge',
    CONTACT_LABEL:'注意事项\n1. 若有填入ID栏位，资料将会更新\n2. 填入Owner栏位时，姓名中间请以空格分开，如：陈 振凯\n3. Company Name、Campaign栏位内容不存在时，将自动建立\n4. 自定义栏位名称若有重复，或与现有栏位名称相同时，资料会被覆盖\n5. 资料内容出现科学记号时，请于Excel中调整储存格格式，避免资料储存错误\n6. 填入日期时，请以yyyy-mm-dd格式输入',
    ORG_LABEL:'注意事项\n1. 若有填入ID栏位，资料将会更新\n2. 填入Owner栏位时，姓名中间请以空格分开，如：陈 振凯\n3. Campaign栏位内容不存在时，将自动建立\n4. 自定义栏位名称若有重复，或与现有栏位名称相同时，资料会被覆盖\n5. 资料内容出现科学记号时，请于Excel中调整储存格格式，避免资料储存错误\n6. 填入日期时，请以yyyy-mm-dd格式输入',
    OPPTY_LABEL:'注意事项\n1. 若有填入ID栏位，资料将会更新\n2. 填入Owner、Oppty Contact、Primary Contact栏位时，姓名中间请以空格分开，如：陈 振凯\n3. Oppty Org、Oppty Contact、Primary Contact、Campaign栏位内容不存在时，将自动建立\n4. 自定义栏位名称若有重复，或与现有栏位名称相同时，资料会被覆盖\n5. 资料内容出现科学记号时，请于Excel中调整储存格格式，避免资料储存错误\n6. 填入日期时，请以yyyy-mm-dd格式输入\n7. 请勿更改Stage Type，并确认Current Stage填写正确',
    CASE_LABEL:'注意事项\n1. 若有填入ID栏位，资料将会更新\n2. 填入Owner、Primary Contact栏位时，姓名中间请以空格分开，如：陈 振凯\n3. Primary Contact、Case Org、Campaign、Group、Type栏位内容不存在时，将自动建立\n4. 自定义栏位名称若有重复，或与现有栏位名称相同时，资料会被覆盖\n5. 资料内容出现科学记号时，请于Excel中调整储存格格式，避免资料储存错误\n6. 填入日期时，请以yyyy-mm-dd格式输入\n7. 请勿更改Stage Type，并确认Current Stage填写正确\n8. Case Number栏位未填写时，将依照流水号设定自动建立',
    KA_LABEL:'注意事项\n1. 若有填入ID栏位，资料将会更新\n2. 填入Owner栏位时，姓名中间请以空格分开，如：陈 振凯\n3. Subject栏位内容不存在时，将自动建立\n4. 资料内容出现科学记号时，请于Excel中调整储存格格式，避免资料储存错误\n5. Content栏位，请以HTML格式填写\n6. Article Number栏位未填写时，将依照流水号设定自动建立',
    COPY:'复制讯息',
    ERROR_2:'请确认汇入档案内容，或使用范本填写资料',
    ERROR_3:'因资料笔数较多，系统仍于背景执行汇入程序，若资料尚未完整汇入，请耐心等候并重新整理页面',
    COPY_2:'已复制',
    SEARCH:'搜寻字串',
    ING:'处理中',
    OPPTY_PRODUCT:'汇出商机产品',
    PROGRESS:'目前进度 : ',
    STILL_RUN:'汇入作业进行中，是否前往"汇入/上传纪录"检视进度?',
  },
  UPCHAT:{
    TITLE:'讯息纪录',
    SENDER:'寄送者 : ',
    SENDER_B:'寄送者 : 机器人',
    ROBOT:'机',
    NO_MESSAGE:'尚无对话纪录',
    SHOW:'显示讯息',
    CHANNEL:'讯息来源',
    BLANK:'请点击对话以显示联络人资讯',
    NO_AGENT:'未指派客服人员',
    SYNC:'您确定要同步此联络人吗？',
    CIC: 'UpDay 客户互动中心',
  },
  DOCUMENT:{
    TITLE:'档案',
    NO_DOC:'尚无档案',
    NAME:'名称',
    DESCRIPTION:"描述",
    CREATE:'建立时间',
    UPDATE:'修改时间',
    ADD:'新增档案',
    EDIT:'修改档案',
    DEL:'删除档案',
    NEW_FILE:'选择新档案',
    MESSAGE_DEL:'您确定要删除此档案吗？',
    MESSAGE_DEL_2:'您确定要删除这些档案吗？'
  },
  BULK_EDIT:{
    TITLE:'批量修改',
    CHANGE_STAGE:'更新流程',
    MESSAGE:`确定要更新以下案件吗？`,
    MESSAGE_CONTACT:'确定要更新以下联络人吗？',
    MESSAGE_OPPTY:'确定要更新以下商机吗？',
    MESSAGE_ORG:'确定要更新以下公司吗？',
    MESSAGE_DATA:'确定要更新已选取的 ',
    MESSAGE_DATA_2:' 笔资料吗？',
    MESSAGE_EMPTY:'请选择要更新的数据',
    ERROR_CASE:'以下案件已结案，如需批量修改，请将结案原因设为空值。',
    ERROR_DEAL:'以下商机已结案，如需批量修改，请更新结案原因并选择重启商机。',
  },
  BULK_EDIT_LIST:{
    0:'维持原数据',
    1:'更新数据',
    2:'设为空值',
  },
  INBOX:{
    SEND:'送出',
    TITLE:'留言板',
    UNREAD:'未读',
    ALL:'全部',
    POST:'贴文',
    ALERT:'提示',
    FOCUS:'提示贴文',
    NWE_POST:'新增贴文',
    SUBSCRIBE:'订阅',
    UNSUBSCRIBE:'取消订阅',
    READ_MORE:'显示全部内容',
    READ_LESS:'显示部分内容',
    DELETE:"您确定要删除此贴文吗？",
    DELETE_ALERT:"您确定要删除此提示吗?",
    CONTENT_EMPTY:"内容空白!",
    CONTENT_NOT_EMPTY:"如果关闭，你将失去已撰写的内容。 你确定要关闭吗?",
    FILE_LIMIT:"上传档案总量大小不可超过20MB",
    ALL_READ:"标示全部为已读",
    ALL_DELETE:"删除全部贴文",
    ALL_DELETE_CHECK:"您确定要删除您所有的贴文吗？",
    ALL_DELETE_ALERT:"删除全部提示",
    ALL_DELETE_ALERT_CHECK:"您确定要删除所有的提示吗？",
    ERROR_POST:'该贴文可能被删除，请重新整理INBOX内容。',
    NO_NOTIFICATION:'暂时没有新消息，现在可以放松一下~',
    ALL_HAVE_READ:'恭喜您，所有消息都已读取!',
  },
  FOLLOW:{
    CONTACT:"追踪或取消追踪所选联络人",
    ORG:"追踪或取消追踪所选公司",
    CASE:"追踪或取消追踪所选案件",
    OPPTY:"追踪或取消追踪所选商机",
    FOLLOW:'追踪',
  },
  UPLOADSTATUS:{
    METHOD:'方式',
    TYPE:'类型',
    CREATE_TIME:"建立时间",
    UPDATED_TIME:"更新时间",
    FILE_NAME:"档案名称",
    TOTAL:"总计",
    SUCCESS:"成功",
    FAIL:"失败",
    STATUS:"状态",
    COMMENT:"纪录",
    UPLOAD:'上传',
    IMPORT:'汇入',
    PROCESSING:"处理中",
    COMPLETE:"完成",
    EMBEDDING:"分析中",
    SHOW:'显示纪录',
    NO_RECORD:'尚无纪录',
    MESSAGE_DELETE:'您确定要删除纪录吗？',
  },
  AL_MAIL:{
    TITLE:'AI撰写助手',
    GENERATE:"AI讯息生成",
    SETTING:"AI生成设定",
    SEND_ALL:"全部送出",
    TONE:"语调",
    LENGTH:"长度",
    LANGUAGE:"语言",
    TIP:"提示",
    SIGN:"签名档",
    REMAKE:"重新生成",
    REMAKEING:"生成中...",
    NO_AMIL:"尚无AI讯息",
    SENDING:"寄送中...",
    FOLLOWUP: "回复查询",
    SUMMARY: "提出摘要",
    CUSTOM: "自订",
    TONE_1:"专业",
    TONE_2:"放松",
    TONE_3:"刺激",
    TONE_4:"有趣",
    TONE_5:"热情",
    LEN_1:"简短",
    LEN_2:"中等",
    LEN_3:"详细",
    STATUS_1:"尚无讯息",
    STATUS_2:"已生成",
    STATUS_3:"已储存",
    STATUS_4:"尚未储存",
    STATUS_5:"失败",
    CHANGE_ERROR:"更改推播渠道或客户分群将会删除已生成的讯息。",
    UNDO:"复原",
    REMAKE_ALL:"您确定要删除现有资料，并以目前设定生成讯息吗?",
    SEND_CHECK:"您确定要送出全部讯息吗?<br>完成后将删除现有讯息、设定。",
    MAKE_MSG:"讯息生成中，请耐心等候。<br>点击更新可确认目前进度。",
    SMS_VENDOR:"请将SMS服务商设定为'三竹简讯'。若未开启此服务，请洽询UpDay服务团队。",
    NO_CONTACT:"尚无可用联络人"
  },
  // GPT----------------------------------------------------------------
  GPT_MENU:{
    DASHBOARD:"仪表板",
    KNOWLEDGE:"知识库",
    CHATBOT:"机器人",
    CHAT_HISTORY:"交谈纪录",
    USER_MANAGEMENT:"用户管理",
    USAGE_ANALYSIS:"数据分析",
    UPLOADSTATUS:'上传纪录',
    BACKTO:"回到",
    MEETINGRECAP:"会议记录",
    AINOTEBOOK:"AI笔记本",
  },
  GPT_KNOWLEDGE:{
    TITLE:"知识库总览",
    NAME : "知识库名称",
    DOCS: "文件总数",
    CREATED_ON: "建立时间",
    UPDATED_ON: "更新时间",
    UPDATED_BY: "更新者",
    ROBOTS: "机器人使用数",
    BACKTO:"回到",
    LAST_UPDATED:"最后更新",
    ONOFF:"开启/关闭 知识库",
    LINKBOT:"连结机器人",
    NO_LINKBOT:"尚未连结",
    CLEAR:"清空",
    CLEAR_ALL:"清空知识库",
    DELETE:'删除知识库',
    DEL_MSG:"您确定要删除此知识库吗?",
    CLEAR_MSG:"您确定要清空此知识库吗?",
    SETTING:"知识库设定",
    UPLOAD:"知识库档案上传",
    ERROR:"此知识库名称已存在",
    PUBLIC:"共用知识库",
    ISPUBLIC:"已共用",
    ADD_COLUMN:"新增栏位",
    EDIT_COLUMN:"修改栏位",
    CLEAR_ERROR:"知识库档案清空中，请稍后重新整理画面。",
    DELETE_ERROR:"知识库档案清空后将进行删除，请稍后重新整理画面。",
    SINGAL_DELETE:"您确定要删除此档案吗?",
    IMAGE_SELECTION: "图片处理选项",
    DELETEING:"档案删除中，请稍后重新整理此页面。",
    TIP_1:"将用以下档案建立知识库，AI机器人会以此知识库内容回答问题。",
    TIP_2:"在此上传Q&A档案，档案需为csv格式且内容使用UTF-8编码。",
    TIP_3:"在此上传压缩档，档案需为zip格式，请直接将档案压缩，不需另外放入资料夹。",
    TIP_4:"在此上传txt、pdf、docx、pptx档案，如需上传csv档，请至Q&A资料上传。",
    TIP_5:"在此新增需要分析的网站，最多可新增5个网站，深度最多为2，新增完成后请按'开始分析'。",
    TIP_6:"在此上传JPG、JPEG、PNG 格式图片。",
  },
  ADD_KNOWLEDGE:{
    TITLE:'新增知识库',
    NAME:'名称',
    DESCRIPTION:'描述',
    NAME_HINT:'知识库建立后，名称将不能修改。',
  },
  GPT_UPLOADIMG:{
    DESCRIBE_IMG: '图像理解',
    CUSTOMER_ATTRIBUTE: '客制属性',
    IMG_SEARCH: '以图搜图',
    TIP_1:"AI会对图像进行详细描述，用户可以根据它提出问题并搜索答案。",
    TIP_2:"AI 将从图像中提取属性并保存到表格中。如果您希望 AI 回答像「亚洲最昂贵的前三项物品」这样的问题，这将非常有用。请联系我们以启用此功能。",
    TIP_3:"AI会分析使用者传送的影像，并为使用者找到相似的影像。对于产品搜寻等场景很有用。",
    FILEUPLOADMESSAGE: '仅接受 JPG、JPEG、PNG 档',
    START_ANALYSIS: '开始分析',
    IMAGE_STATUS: '图片状态',
  },
  GPT_CLOUD:{
    CONNECT_CLOUD: '连结云端',
    CONNECT_ACCOUNT: '连结帐号',
    RESET: '重置',
    SAVE: '储存',
    DELETE_CHECK: '您确定要删除此云端帐号设定吗',
    CLOUDSYNC_ERROR: '删除失败，请重试',
    MY_DRIVE: '我的档案',
    SHARE_WITH_ME: '已共用',
    SHAREPOINT: 'Sharepoint',
    EXCEED_MAX_SIZE: '上传档案总量大小超过'
  },
  GPT_ANALYSIS:{
    NAME : "数据分析",
    USED_TOKEN:"消耗 Token",
    MSG_TOKEN:"平均每讯息消耗 Token",
    MSG_Q:"讯息量",
    MSG_PER_Q:"平均花费 Token",
    ACU:"累积消耗 Token",
    THIS_PERIOD:'本期',
    PREVIOUS:'上期',
    TABLE_HEADER_1:"智能机器人",
    TABLE_HEADER_2:"使用类型",
    TABLE_HEADER_3:"Prompt Tokens",
    TABLE_HEADER_4:"Completion Tokens",
    TABLE_HEADER_5:"费用",
    TABLE_HEADER_6:"时间",
  },
  GPT_AIBOTS:{
    TITLE:"机器人总览",
    AIASSISTANTNAME: 'AI助理名称',
    AIASSISTANTNAME_TIP1: '设定您AI助理的名称',
    AIASSISTANTNAME_TIP2: '范例: 小安',
    AIASSISTANTROLE: 'AI助理角色',
    AIASSISTANTROLE_TIP1: '设定您AI助理所扮演的角色',
    AIASSISTANTROLE_TIP2: '范例: customer service agent (客服人员) for UpDay, a CRM production designed by 安然科技股份有限公司',
    TOPIC: '主题',
    TOPIC_TIP1: '提供AI助理一个主题，使其能更好的改写提问',
    TOPIC_TIP2: '范例: UpDay CRM',
    TOPICLIMIT: '主题限制',
    TOPICLIMIT_TIP1: '如果问题超出主题,AI助理会回到应有的主题范围回覆',
    TOPICLIMIT_TIP2: '范例: technology',
    REPLY_LANGUAGE: '回覆语言',
    REPLY_LANGUAGE_TIP: 'AI助理会依据所选择的语言回覆',
    MODEL:'AI模型',
    MEMORY_LENGTH:'机器人记忆长度',
    RETURN_KNOWLEDGE:'使用知识数量',
    INBOX:"收件匣",
    HAS_AGENT_BOT:"此收件匣已连结其他机器人。",
    NO_AGENT_BOT:"尚无UpChat机器人，请联系UpGPT客服进行设置。",
  },
  ADD_AIBOTS:{
    TITLE:'新增机器人',
    NAME:'名称',
    DESCRIPTION:'描述',
    INBOX:"收件匣",
    KNOWLEDGE:"知识库",
    MEMORY_LENGTH:'记忆长度',
    RETURN_KNOWLEDGE:'使用知识量',
    IMAGE_SEARCH: '允许图片搜寻',
    RETURN_IMAGE_QUANTITY: '回传图片数量',
    ROLE:"角色",
    SCOPE:"回覆限制",
    TOPIC:"主题",
    ONOFF:"开启/关闭 机器人",
    DETAIL:"机器人详细资料",
    BRAIN_SIZE:"知识储备",
    C_SETTING:"角色设定",
    DEL_MSG:"您确定要删除此机器人吗?",
    M_1:"微调知识设定。",
    M_2:"机器人将根据提供的知识库进行回应。",
    M_3:"设定愈多的记忆体长度会参考愈多的上下文，但回应速度会减慢。\n设定愈多的使用知识量，将提供更精确的回应，但速度会减慢。",
    M_4:"机器人会根据使用者的角色设定做出反应和回应。",
    M_5:"角色会影响回应的语气和态度。",
    M_6:"如果问题超出主题，机器人会回到应有的主题范围回覆。",
    M_7:"提供机器人一个主题，使其能更好的改写提问。",
    M_8:"机器人的自称。",
  },
  GPT_UPLOAD:{
    DEL_K:"已删除的知识库",
    AutoSync: "云端自动同步", 
    ManualSync: "云端手动同步",
    AutoKnow: "连接的知识库"
  },
  GPT_USER:{
    T_1:"个人资讯",
    T_2:"变更密码",
  },
  GPT_CHAT_HISTORY:{
    CHANNEL:"渠道",
    C_USER_NAME:"使用者名称",
    LAST_CHAT_TIME:"最后对话时间",
    L_SELECT:"请从左侧选择一个对话",
    SEARCH:"搜寻讯息",
  },
  GPT_MEETINGRECAPS:{
    TITLE:"会议记总览",
    NAME:"名称",
    participants:"参与者",
    OWNER:"拥有者",
    TIME:"时间",
    DELETE:"您确定要删除此会议纪录吗？"
  },
  GPT_MEETINGRECAP:{
    TITLE:"会议记录",
    NAME:"会议名称",
    MS:"会议总结",
    OWNER:"拥有者",
    MS_TIME:"会议时间",
    ANR:"关联AI记事本",
    AN:"AI 记事本",
    OPPTY:"商机",
    ANs:"AI笔记",
    TS:"文字纪录",
    ANALYZE: "AI分析",
    SUGGEST_TASKS:"建议任务",
    CREATE_TASKS:"创建任务",
    VIEW_TASKS:"检视任务",
    SUMMARY:"生成总结",
    VALIDATE:"请确认必填栏位，并绑定商机。",
    NO_T:"没有文字纪录。",
    DELETED_MEETING_RECAP:"此会议记录已被删除，请重新整理页面",
    EXIST_SUMMARY_AND_TASK:"会议总结及任务已被生成，请重新整理页面并确认内容",
    MAIL_SETTING:"信件设定确认中",
    MAIL_GENERATE:"AI 信件生成中",
    EMPTY_SUMMARY:"AI 无法从空白的会议总结中生成合适的email",
    EMPTY_CUSTOM_PROMPT:"AI 无法以空白的prompt生成合适的email",
    EMPTY_SUMMARY_TASK:"AI 无法从空白的会议总结中生成合适的任务",
    TASK_CANT_FIND_ACTIVITY:"相关的活动已被删除，请再次创建新的任务",
  },
  GPT_AINOTEBOOK:{
    TITLE:"AI笔记本总览",
    LAST_UPDATED:"最后更新",
    DESCRIPTION:'描述',
    RECORD_TYPE:'纪录类型',
    DELETE:"您确定要删除此AI笔记本吗？",
    DELETE_ERROR:"至少需有一个AI笔记本",
    DEFAULT:"系统预设",
    CRM_TIP:"选择您希望小安在会议中自动为您撷取的 CRM 资料。",
    AI_NOTE_TIP:"自定义您希望小安在会议中自动为您撷取的资料。",
  },
  ADD_AINOTEBOOK:{
    TITLE:'新增AI笔记本',
    EDIT:'编辑AI笔记本',
    ADD: '新增栏位',
    COLUMN: '栏位',
    NAME:'名称',
    DESCRIPTION:'描述',
    DELETE_ERROR:"您确定要删除此栏位吗？",
    NAME_HINT:'AI笔记本建立后，名称将不能修改。',
    COLUMN_NAME_HINT:'栏位建立后，名称将不能修改。',
    LIMIT_HINT:'栏位描述限制50字。',
    LIMIT_COLUMNS:'栏位数量已达上限。',
    CRM_SYSTEM_FIELD: 'CRM栏位',
  },
  //Up Meeting
  UPMEETING:{
    LOGIN:"UpSide AI助理",
    ANN:"小安",
    CHANGE_AN:"更换AI记事本将会清除已纪录的资讯。",
    NOTE_C:"个栏位已完成填写。",
    MEETING_OVER:"您的会议已结束，点击以下连结查看纪录：",
    L_D_TITLE:"是否保留上次参加的会议?",
    L_D_LAST:"继续之前的会议",
    L_D_NEW:"开始新的会议",
    FINISH:"结束",
    CAPTION_ERROR:"请先开启Teams或Google会议。",
    IMAGE_PREFIX:"AI问题分析中...",
    QUERY_PREFIX:"AI关键字生成中...",
    SEARCH_PREFIX:"AI资讯分析中...",
    IMAGE_ERROR:"图片分析失败，请在试一次。",
    BACKEND_ERROR:"系统设定有误，请联系系统管理员。",
    SHOT_BTN_TITLE:"AI资讯分析",
    NO_QA:"请点击AI图示或输入关键字进行搜寻。",
    Problem_Analysis:"问题分析",
    Causes_Analysis:"原因分析",
    Solutions:" 解决方案",
    Actions:"行动",
    References:"参考资料",
    OutlookError:"没有开启的邮件，或是设定不符",
  },
  //Other
  BUSINESS_TYPE:{
    OPPTY:"商机",
    CASE:"案件",
    COMPANY:"公司",
    CONTACT:"联络人",
    AI_NOTEBOOK:"AI笔记本",
  }
}
