export const locale = {
  GENERAL: {            
    CANCEL: '取消',      
    CONFIRM: '確認',
    EDIT:'編輯',
    ADD:'新增',
    COPY:'複製',
    DELETE:'刪除',
    SAVE:'儲存',
    SHOW_CONTENT: '展開',
    HIDE_CONTENT: '收合',
    AUTH:'無權查看此物件內容',
    DAY:'天',
    ALL:'全部',    
    RELEASE:'發布',
    RESTART_EDITING:'重啟編輯',
    CHANGE_OWNER:'變更擁有者',
    CHANGE_OWNER_2:'變更客服人員',
    CHANGE_OWNER_3:'變更作者',
    ADD_DEAL:'新增商機',
    ADD_Case:'新增案件',
    CLOSE:'關閉',
    PIN:'釘選',
    FIELDHISTORY:'變更紀錄',
    NO_FIELDHISTORY:'尚無變更紀錄',
    NO_EMAIL:'尚無電郵',
    NO_SMS:'尚無簡訊',
    ACTIVE:'執行',
    LOGOUT:'登出',
    SUCCESS:'成功',
    DASHBOARD: '儀表板',
    DATASHEET:'資料表',
    NEXT:'下一步',
    PRE:'上一步',
    NONE:'無',
    MAIL:'電子郵件',
    LAST_NAME: '姓氏',
    FIRST_NAME: '名稱',
    SHORTCUTS: '捷徑',
    STATUS: '狀態設定',
    REFRESH: '更新',
    SETTING_BTN:'資料表&篩選',
    PRODUCT:'產品',
    ACTIVITY:'活動',
    TO:'收件者',
    CC:'副本',
    SENDER:'寄件者',
    INCOMING:"收件",
    OUTGOING:"寄件",
    RELOAD: '重整',
    LOGOUT_MSG:"您確定要登出嗎?",
    SEARCH:"搜尋",
    UPDATE:"更新",
  },
  //------- Log in
  LOGIN:{
    ACCOUNT:'帳號',
    PWD:'密碼',
    TENANT:'租戶名稱',
    TRY:'免費試用',
    LOGIN:'登入',
    LOGIN_2:'登入 UpDay',
    LOGIN_UPCHAT:'登入 UpChat',
    LOGIN_UPGPT:'登入 UpGPT',
    GET_VALI_CODE: '取驗證碼',
    LOGIN_VALI_CODE: '驗證碼',
    DESCRIPTION:'一站式輕鬆管理所有客戶資訊',
    PASSWORD:'忘記密碼',
    ERROR:'請輸入帳號密碼',
    ERROR_2:'請輸入租戶名稱',
    ERROR_3:'帳號或密碼錯誤，請重試',
    ERROR_3_VALI_CODE:'帳號或密碼或驗證碼錯誤，請重試', 
    ERROR_4:'請輸入帳號取得驗證碼',
    SENT_VALI_CODE:'驗證碼已發送至您的電郵',
    LOGIN_VAL_ERROR:'無法發送驗證碼。詳情請聯繫系統管理員',
  },
  FORGET_PASSWORD:{
    TITLE:'忘記密碼',
    TENANT:'租戶名稱',
    MAIL:'E-Mail',
    ERROR:'輸入資料有誤，請確認後再試一次。',
  },
  TRY_IT_FREE:{
    TITLE:'免費試用 UpDay 14天',
    FIRST_NAME:'*名稱',
    LAST_NAME:'*姓氏',
    ORG_NAME:'公司名稱',
    ROLE:'職稱',
    PHONE:'聯絡電話',  
    MESSAGE:'信息',   
    MESSAGE_2:'謝謝您申請，我們將盡快與您聯繫',
    ERROR:'請輸入有效的電子郵件', 
  },
  //------- Bower Title
  Bower_Title:{
    UPCHAT:'Upday CIC - 客戶互動中心',
    Oppty_Dashboard: '商機案件儀表板 - UpDay',
    Case_Dashboard: '客服案件儀表板 - UpDay',
    Activity: '活動',
    Oppty_List:'商機總覽',
    Oppty:'商機',
    Deal:'商機',
    Contacts_List:'聯絡人總覽',
    Contact:'聯絡人',
    Company_List:'公司總覽',
    Company:'公司',
    Account: '公司',
    Campaign_List:'活動總覽 - UpDay',
    Campaign:'行銷活動',
    Segment_List:'分群總覽 - UpDay',
    Segment:'分群',
    Case_List:'案件總覽',
    Case:'案件',
    KnowledgeArticle_List:'知識庫文章總覽 - UpDay',
    KnowledgeArticle:'知識庫文章',
    Setting:'設定 - UpDay',
    Login:'登入UpDay',
    ResetPasswordogin:'重設密碼 - UpDay',
    PermissionError:'權限不足 - UpDay',
    PermissionError_PAGE:'無權查看此物件內容',
    PageNotFoundError:'此物件不存在 - UpDay',
    PageNotFoundError_PAGE:'此物件不存在，請返回上一頁。',
  },
  //---------Rule
  RULE:{
    RULE_R_NUM:'此欄位為必填，請填寫數字',
    RULE_R_NUM_2:'請填寫17位內的數字(可包含小數點後兩位)',
    RULE_R_NUM_3:'請填寫小數點後兩位的數字',
    RULE_R_NUM_4: '請填寫17位內的數字',
    RULE_R_NUM_5: '請填寫正數',
    RULE_R_NUM_6: '請填寫1~2的整數',
    RULE_NUM:'請填寫數字',
    RULE_R:'此欄位為必填',
    RULE_R_LASTNAME:'聯絡人姓氏為必填',
    RULE_R_LASTNAME_2:'姓氏為必填',
    RULE_R_LASTNAME_3:'使用者姓氏為必填',
    RULE_R_FIRSTNAME: '名稱為必填',
    RULE_R_COMPANY_NAME:'公司名稱為必填',
    RULE_R_OPPTY_NAME:'商機名稱為必填',
    RULE_R_OPPTY_Contact:'客戶名稱為必填',
    RULE_R_Case_Contact:'客戶名稱為必填',
    RULE_R_TEMPLATE_NAME:'範本名稱為必填',
    RULE_R_Case:'案件名稱為必填',
    RULE_R_SEGMENT:'分群名稱為必填',
    RULE_R_CAMPAIGN:'活動名稱為必填',
    RULE_R_ACCOUNT:'聯絡人帳號為必填',
    RULE_R_PROCESS:'流程名稱為必填',
    RULE_R_STAGE:'階段名稱為必填',
    RULE_R_TITLE:'標題為必填',
    RULE_R_NAME:'名稱為必填',
    RULE_HTTP:'開頭請填寫 http://或 https://',
    RULE_URL:'請填寫正確URL格式',
    RULE_EMAIL:'請填寫正確E-mail格式',
    RULE_PHONE:'請填寫正確電話號碼格式',
    RULE_PHOTO:'請上傳圖檔',      
    RULE_CSV:'請上傳CSV檔',
    RULE_FILE:'附件檔案總共不能超過20MB',
    RULE_FILE_2:'照片大小請小於1MB',
    RULE_FILE_3:'檔案大小請小於20MB',
    RULE_FILE_4:'照片大小請小於20MB',
    RULE_DUE_DATE:'預期結案日期為必填',      
    RULE_PROCCESS:'交易流程為必選',
    RULE_FORECAST:'預測類別為必選',
    RULE_REASON:'失敗原因為必選',
    RULE_NOTE:'備註內容為必填',
    RULE_NOTE_1000:'備註內容不可超過1000字',
    RULE_DATE:'請填選正確日期格式 YYYY-MM-DD',
    RULE_DATETIME:'請填選正確格式 YYYY-MM-DD HH:mm',
    RULE_5:'字數請限制在5字內', 
    RULE_9:'字數請限制在9字內', 
    RULE_10:'字數請限制在10字內',
    RULE_16:'字數請限制在16字內', 
    RULE_17:'字數請限制在17字內', 
    RULE_20:'字數請限制在20字內',
    RULE_30:'字數請限制在30字內',
    RULE_50:'字數請限制在50字內',
    RULE_10_50:'字數請限制在10~50字內',
    RULE_60:'字數請限制在60字內',
    RULE_70:'字數請限制在70字內',
    RULE_86:'字數請限制在86字內',    
    RULE_90:'字數請限制在90字內',  
    RULE_100:'字數請限制在100字內',
    RULE_200:'字數請限制在200字內',
    RULE_250:'字數請限制在250字內',
    RULE_300:'字數請限制在300字內',
    RULE_500:'字數請限制在500字內',
    RULE_1000:'字數請限制在1000字內',
    RULE_NULL: '請輸入文字',
    RULE_HHMM: '格式應為HH:MM',
    RULE_PROGRESS: '請填入0-100',
    RULE_CF_1:"選項不可重複",
    RULE_CF_2:'選項不可包含","',
  },
  BREADCRUMBS:{
    Contacts:'聯絡人',
    Companies:'公司',
    Deals:'商機',
    Case:'客服案件',
    Campaign:'行銷活動',
    Segment:'客戶分群',
    KnowledgeArticles:'知識庫文章',
  },
  //-----DASHBOARD
  DASHBOARD:{
    TITLE: '商機案件儀表板',
    EXPECTED_REVENUE:'預期營收',
    SUCCESSFULLY_CLOSED:'成功結案',
    CURRENT_PERIOD:'本期結算',
    EMAIL_PERFORMANCE:'郵件成效',
    STAGE_AMOUNT:'各階段金額',
    SALES_RANKING:'銷售排行',
    LATEST_OPPTY:'最新商機',
    WITHIN_30_DAYS:'30天內',
    BEST_OPPTY:'最佳商機',
    TOP_5:'前五名',
    TODAY: '今天',
    THIS_YEAR:'今年',
    THIS_SEASON:'本季',
    THIS_MONTH:'本月',
    THIS_WEEK:'本週',
    SELECT_DATE:'選擇日期',
    //----Headers
    OPPTY_OWNER:'商機擁有者',
    CONTACT_NAME:'客戶名稱',
    OPPTY_NAME:'商機名稱',
    CURRENT_STAGE:'目前階段',
    CREATED_TIME:'建立時間',
    AMOUNT:'金額',
    EXPECTED_CLOSE_DATE:'預期結案日期',
    THIS_PERIOD:'本期',
    PREVIOUS:'前期',
    DIFFERENCE:'相差',
    QUANTITY_SENT:'已寄出數量',
    READ:'已閱讀數量',
    CLICKED:'已點擊郵件連結',
    UNKNOWN:'不明',
    GO_OPPTY:'前往商機'
  },
  //------- CONTACT
  CONTACT: {
    TITLE: '聯絡人',      
    BULK:'推播',
    SEGMENT:'建立客戶分群',
    EMAIL_PUSH:'Email推播',
    SMS_PUSH:'SMS推播',
    MESSAGE_DELETE:'您確定要刪除此聯絡人嗎？',
    MESSAGE_FIRST:'請先刪除', 
    MESSAGE_OPPTY:'相關商機', 
    MESSAGE_OPPTY_2:'相關商機',
    MESSAGE_CASE:'相關案件', 
    MESSAGE_CASE_2:'相關案件', 
    MESSAGE_ALL:'請先刪除相關商機或案件',
    MESSAGE_ERROR:'此聯絡人有商機正在進行，請指派他人後再刪除',
  },
  ADDSTATICSEGMENT:{
    TITLE: '新增靜態客戶分群',    
    NAME:'客戶分群名稱',
  },
  CONTACT_DETAIL: {      
    TITLE: '職稱',      
    ORG_NAME:'公司名稱',
    OWNER:'聯絡人擁有者',
    CONTACT_INFORMATION:'聯絡人資訊',
    FIRST_NAME:'名稱',
    LAST_NAME:'姓氏',
    MOBILE_PHONE:'行動電話',
    OFFICE_PHONE:'聯絡電話',
    BIRTHDAY:'生日',
    ADDRESS:'地址',
    CITY:'城市',
    COUNTRY:'國家',
    LEVEL:'級別',
    ZIP_CODE:'郵遞區號',
    DEPARTMENT:'部門',
    PROVINCE:'省份',
    RELATED_CAMPAIGN:'相關活動',
    DESCRIPTION:'描述',      
    COMPANY_INFORMATION:'公司資訊',
    ADD_COMPANY_INFORMATION:'新增公司資訊',
    NO_COMPANY_INFORMATION:'尚無公司資訊',     
    COMPANY_NAME:'公司名稱',
    JURISTIC_PERSON_NAME:'法人名稱',
    POSITION:'職位',
    SHAREHOLDING:'持股',
    FAX:'傳真',
    //----NOTE
    ADD_NOTE:'新增備註',
    NO_NOTE:'尚無備註',
    //----Oppyt
    NO_DEAL:'尚無商機', 
    //----Timeline   
    TIMELINE:'時間軸',
    NO_TIMELINE:'尚無時間軸',
    TIMELINE_TITLE:'職稱已更改為',
    TIMELINE_DEPARTMENT:'部門已更改為',
    TIMELINE_LEVEL:'級別已更改為',
    TIMELINE_OWNER:'聯絡人擁有者已更改為',
    //----TAB
    DETAIL:'詳細資料',
    NOTE:'備註',
    EMAIL:'電郵',
    SMS:'簡訊',
    UPCHAT:'歷史訊息',
    ALL_OPPORTUNITIES:'所有商機',
    ALL_CASES:'所有案件',
    DEAL:'商機名稱',   
    STAGE:'目前階段', 
    //----MESSAGE
    MESSAGE:'您確定要刪除此備註嗎？',
    MESSAGE_2:'您確定要刪除此商機嗎？',
    MESSAGE_3:'您確定要刪除此聯絡人嗎？',
    MESSAGE_4:'您確定要複製此聯絡人嗎？',
    MESSAGE_5:'您確定要刪除此聯絡人的公司資訊嗎？',
    ERROR:'請選擇圖檔',
    ERROR_2:'照片請小於5MB',
    ERROR_3:'請輸入正確格式',
    ERROR_4:'未輸入公司名稱',
    ERROR_5:'已有此間公司',
    ERROR_6_1:'此聯絡人為商機',
    ERROR_6_2:'之客戶，請重新指派客戶後再行刪除',
    ERROR_7:'此聯絡人相關商機正在進行，請重新指派再刪除',
    ERROR_8:'此聯絡人無公司資訊',
  },
  ADD_CONTACT: {
    TITLE: '新增聯絡人',
    FIRST_NAME:'聯絡人名稱',
    LAST_NAME:'聯絡人姓氏',
    SELECT_LABEL:'地區',
    MOBILE_PHONE:'行動電話',
    OFFICE_PHONE:'辦公室電話',
    LEVEL:'級別',
    ORG_NAME:'公司名稱',
    IMAGE:'更新聯絡人照片',      
  },
  EDIT_CONTACT: {
    TITLE: '修改聯絡人',
    FIRST_NAME:'聯絡人名稱',
    LAST_NAME:'聯絡人姓氏',
    SELECT_LABEL:'地區',
    MOBILE_PHONE:'行動電話',
    OFFICE_PHONE:'辦公室電話',
    LEVEL:'級別',
    ORG_NAME:'公司名稱',
    IMAGE:'更新聯絡人照片',
  },
  ADDCONTACTORG:{
    TITLE:'新增公司資訊',
    COMPANY_NAME:'公司名稱',
    JURISTIC_PERSON_NAME:'法人名稱',
    POSITION:'職位',
    SHAREHOLDING:'持股',
    ERROR:'已有此間公司',
  },
  //------- COMPANY
  COMPANY:{
    TITLE: '公司', 
    //----MESSAGE
    MESSAGE:'您確定要刪除此公司嗎？',
    MESSAGE_2_1:'請先刪除',
    MESSAGE_2_2:'相關商機',
    MESSAGE_2_3:'相關案件',
    MESSAGE_3:'請先刪除相關商機或案件',
  },
  COMPANY_DETAIL:{
    PHONE:'電話',
    ADDRESS:'地址',
    WEB:'網站',
    OWNER:'擁有者',
    INFO:'公司資訊',
    NAME:'公司名稱',
    COMPANY_NUMBER:'統一編號',
    FAX:'傳真',
    TYPE:'類型',
    INDUSTRY:'行業別',
    EMPLOYEE:'員工人數',
    ANNUAL_REVENUE:'營業額',
    REGISTERED_CAPITAL:'資本額',
    ESTABLISH_DATE:'成立日期',
    RELATED_CAMPAIGN:'相關活動',
    DESCRIPTION:'描述',
    CITY:'城市',
    COUNTRY:'國家',
    LEVEL:'級別',
    ZIP_CODE:'郵遞區號',      
    PROVINCE:'省份',
    //----NOTE
    ADD_NOTE:'新增備註',
    NO_NOTE:'尚無備註',
    //----Oppyt
    NO_DEAL:'尚無商機',       
    STAGE:'目前階段',
    //----primary contact
    CONTACT:'主要聯絡人',
    NO_CONTACT:'尚無主要聯絡人',
    //----Timeline   
    TIMELINE:'時間軸',
    NO_TIMELINE:'尚無時間軸',          
    TIMELINE_EMPLOYEE:'員工人數已更改為',
    TIMELINE_REVENUE:'年度營業額已更改為 $',
    TIMELINE_CAPITAL:'公司資本額已更改為 $',
    TIMELINE_OWNER:'聯絡人擁有者已更改為',
    TIMELINE_PEOPLE:'人',
    //----TAB
    DETAIL:'詳細資料',
    NOTE:'備註',      
    ALL_OPPORTUNITIES:'所有商機',
    ALL_CASES:'所有案件',
    DEAL:'商機名稱',
    //----MESSAGE
    MESSAGE:'您確定要刪除此備註嗎？',
    MESSAGE_2:'您確定要刪除此商機嗎？',
    MESSAGE_3:'您確定要刪除此公司嗎？',      
    ERROR:'請選擇圖檔',
    ERROR_2:'照片請小於5MB',      
  },
  ADD_COMPANY: {
    TITLE: '新增公司',
    ORG_NAME:'公司名稱',
    TYPE:'類型',
    INDUSTRY:'行業別',
    IMAGE:'上傳客戶LOGO',
    WEB:'網站',
    PHONE:'電話',
    FAX:'傳真',
    ADDRESS:'地址',
    EMPLOYEE:'員工人數',      
  },
  Edit_COMPANY: {
    TITLE: '修改公司',
    ORG_NAME:'公司名稱',
    TYPE:'類型',
    INDUSTRY:'行業別',
    IMAGE:'上傳客戶LOGO',
    WEB:'網站',
    PHONE:'電話',
    FAX:'傳真',
    ADDRESS:'地址',
    EMPLOYEE:'員工人數',      
  },
  ADDMAINCONTACT:{
    TITLE: '新增公司聯絡人',
    TITLE_2: '修改公司聯絡人',
    NEW_CONTACT:'建立全新聯絡人',
    OLD_CONTACT:'選擇已有聯絡人',
    CONTACT_NAME:'聯絡人姓名',
    FIRST_NAME:'聯絡人名稱',
    LAST_NAME:'聯絡人姓氏',
    JURISTIC_PERSON_NAME:'法人名稱',
    POSITION:'職位',
    SHAREHOLDING:'持股',
    PRIMARY_CONTACT:'主要聯絡人',
    ERROR:'如需新增全新聯絡人，請點選上方＂新增全新聯絡人＂按鈕。如無此需求，請選擇已建立之聯絡人',
  },
  MAINCONTACTS:{
    TITLE: '公司聯絡人',
    PRIMARY:'主要',
    CONTACT_NAME:'聯絡人姓名',
    JURISTIC_PERSON_NAME:'法人名稱',
    POSITION:'職位',
    SHAREHOLDING:'持股',
    PHONE:'電話',
    MAIL:'電子郵件',
    ACTIVE:'執行',
    MESSAGE:'您確定要刪除此聯絡人嗎？',
  },
  //------- DEAL
  DEAL:{
    TITLE: '商機',
    OPPTIES:'商機',
    NO_DEAL:'尚無商機',
    NO_DEAL_STAGE:'尚無商業流程',
    PROCESS: '商業流程',
    //----MESSAGE
    MESSAGE:'您確定要刪除此商機嗎？',
    ERROR:'請先建立商業流程',
    DELETE_ERROR:'以下商機已結案，不可刪除。',
  },
  DEAL_DETAIL:{
    CHANGE_STAGE:'變更商業流程',
    REOPEN:'重啟商機',
    NAME: '商機名稱',
    EXPECTED_CLOSE_DATE:'預期結案日期',
    CREATED_ON: '建立日期',
    STATUS: '狀態',
    AMOUNT:'金額',
    SCORE:'分數',
    PROGRESS:'達成率',
    OWNER:'商機擁有者',
    CLIENT_NAME:'客戶名稱',
    NEW_CLIENT:'建立全新客戶',
    CONTACT:'主要聯絡人',
    OPPTY_CONTACT:'商機聯絡人',
    NO_OPPTY_CONTACT:'尚無主要聯絡人',
    CLIENT_BUDGET:'客戶預算',
    FORECAST_CATEGORY:'預測類別',
    RELATED_CAMPAIGN:'相關活動',
    CUSTOMER_NEEDS:'客戶需求',
     //----Timeline   
     TIMELINE:'時間軸',
     NO_TIMELINE:'尚無時間軸',
     NO_AMOUNT_CHANGE:'尚無金錢變更',
     NO_STAGE_CHANGE:'尚無階段變更',
     NO_FIELD_CHANGE:'尚無其他變更',
     NO_ACTIVITY_CHANGE:'尚無活動紀錄',
     NO_MAIL:'尚無電郵',
     NO_SMS:'尚無簡訊', 
     NO_NOTE:'尚無備註',
     TIMELINE_AMOUNT:'商機金額已更改為 $',
     TIMELINE_NAME:'商機名稱已更改為',
     TIMELINE_FORECAST:'預測類別已更改為',
     TIMELINE_PROCESS:'商機流程已更改為',
     TIMELINE_OWNER:'聯絡人擁有者已更改為',
     TIMELINE_ECD:'商機預期結案日已更改為',
     SOON_DUE: "即將於",
     SOON_DUE_1:'天後到期',
     SOON_DUE_2:"已過期超過",
     SOON_DUE_3:"天",
     SOON_DUE_4:"今天到期",
    //----TAB      
    DETAIL:'詳細資料',
    NOTE:'備註',
    EMAIL:'電郵',
    SMS:'簡訊',      
    ALL_CASES:'所有案件',
    //----timeLineType
    TIMELINE_TAB_ALL:'全部',
    TIMELINE_TAB_AMOUNT:'金錢變更',
    TIMELINE_TAB_STAGE:'階段變更',
    TIMELINE_TAB_FIELD:'其他變更',
    TIMELINE_TAB_MAIL:'電郵',
    TIMELINE_TAB_SMS:'簡訊',
    TIMELINE_TAB_NOTE:'備註',
    //----MESSAGE
    MESSAGE:'您確定要刪除此備註嗎？',
    MESSAGE_2:'您確定要刪除此商機嗎？',
    MESSAGE_3:'結案日期將會被覆蓋，請問是否仍要重啟？',      
  },
  ADD_DEAL:{
    TITLE: '新增商機',
    NAME: '商機標題',
    ORG:'選擇公司',
    CONTACT:'選擇聯絡人',
    NO_CONTACT:'請輸入聯絡人姓名進行搜尋',
    NEW_CONTACT:'建立全新聯絡人',
    OLD_CONTACT:'選擇已有聯絡人',
    AMOUNT:'商機金額',
    PROCESS:'請選擇商業流程',
    EXPECTED_CLOSE_DATE:'預期結案日期',
    FORCAST:'預測類別',
    DEAL_PROCESS:'商業流程',
    ERROR:'必須選擇已建立的交易流程',
    ERROR_2:'必須選擇已建立的聯絡人或點擊全新聯絡人',
    ERROR_3:'客戶與公司須至少填其一',
  },
  TO_DEAL:{
    NAME: '客戶名稱',
    EXPECTED_CLOSE_DATE:'預期結案日期',
    AMOUNT:'金額',
    SCORE:'分數',
    PROGRESS:'達成率',
    OWNER:'商機擁有者',
    INFO:'基本資料',
    GUIDE:'成功指引',
    BUDGET:'確認預算',
    PROBABILITY:'可能性',
    DESCRIPTION:'描述',
    DETAIL_PAGE:'前往頁面',
    IN:'於',
    LOST:'失敗',
    CLOSED:'結案',
    WIN:'成功結案',
    BECAUSE:'因'
  },
  RELATEDCONTACTS:{
    TITLE:'聯絡人',
    NEW_CONTACT:'建立全新聯絡人',
    OLD_CONTACT:'選擇已有聯絡人',
    FIRST_NAME:'聯絡人名稱',
    LAST_NAME:'聯絡人姓氏',
    NO_CONTACT:'請輸入聯絡人姓名進行搜尋',
    ADD_CONTACT:'新增聯絡人',
    CONTACT_NAME:'聯絡人姓名',
    ORG_NAME:'公司名稱',      
    PHONE:'電話',
    MAIL:'電子郵件',
    ACTIVE:'執行',
    MESSAGE:'您確定要刪除此聯絡人嗎？',
    ERROR:'如需新增全新聯絡人，請點選上方＂新增全新聯絡人＂按鈕。如無此需求，請選擇已建立之聯絡人',
  },
  CHILD_CASE:{
    TITLE:'子案件',
    SUBJECT: '主題',
    CONTACT:'聯絡人',
    PRIORITY:'優先等級',
    STATUS:'狀態',
    GROUP:'組別',
    TYPE:'類型',
    OWNER:'客服人員',
    CLOSED_DATE:'結案日期',
    ACTIVE:'執行',
    ADD_CHILD_CASE:'新增子案件',
    NO_CHILD_CASE: '無符合搜尋字詞的結果',
    SEARCH_CHILD_CASE: '請輸入案件標題進行搜尋',
    MESSAGE:'您確定要刪除此子案件嗎？',
    ERROR: '請選擇已建立之案件'
  },
  EDITDEALDATE:{
    TITLE:'修改預期結案日',
    NAME:'商機名稱',
    EXPECTED_CLOSE_DATE:'預期結案日期',
  },
  //------- Case
  CASE:{
    TITLE: '客服案件',
    CASE:'案件',
    NO_CASE:'尚無案件',
    NO_CASE_STAGE:'尚無案件流程',
    FINISH:'結案',
    CLOSED:'關閉',
    CASE_NUMBER:'案件編號',
    OWNER:'客服人員',
    //----MESSAGE
    MESSAGE:'您確定要刪除此案件嗎？',
    ERROR:'請先建立案件流程',
    DELETE_ERROR:'以下案件已結案，不可刪除。',
  },
  CASE_DETAIL:{      
    CHANGE_STAGE:'變更案件流程',
    CLOSED:'結案',
    REOPEN:'重啟案件', 
    IN:'於',
    CONTACT:'主要聯絡人',
    PHONE:'進線電話',
    EMAIL:'進線電郵',
    EMAIL_2:'電郵',
    EXPECTED_CLOSE_DATE:'預期結案日期',
    CREATED_ON:'建立日期',
    PRIORITY:'優先等級',      
    STATUS:'狀態',
    OWNER:'客服人員',      
    GUIDE:'客服指引',
    DAY:'天',
    CASE_NAME:'案件標題',
    CASE_NUMBER:'案件編號',
    ORG_NAME:'公司名稱',
    INCOMING_CONTACT:'進線聯絡人',
    SOURCE:'案件來源',
    GROUP:'案件組別',
    TYPE:'案件類型',
    CASE_STATUS:'案件狀態',
    RELATED_CAMPAIGN:'相關活動',
    DESCRIPTION:'案件描述',
    DESCRIPTION_2:'案件處理',
    PARENT_CASE:'母案件',
    //----TAB
    DETAIL:'詳細資料',
    NOTE:'備註',      
    SMS:'簡訊',   
    PRO:"產品",
    RELATED_ARTICLE:'相關文章',   
    ALL_ARTICLE:'所有文章',
    STAGE_CHANGE:'階段變更',
    STATUS_CHANGE:'狀態變更',
    FIELD_CHANGE:'其他變更',
    SUBJECT:'標題',
    CLOSED_DATE:'結案日期',
    //----Timeline   
    TIMELINE:'時間軸',
    NO_TIMELINE:'尚無時間軸',
    NO_STAGE_CHANGE:'尚無階段變更',
    NO_STATUS_CHANGE:'尚無狀態變更',
    NO_FIELD_CHANGE:'尚無其他變更',
    NO_NOTE:'尚無備註',
    NO_MAIL:'尚無電郵',
    NO_SMS:'尚無簡訊',
    ADD_NOTE:'新增備註',
    ADD_PRO:'新增產品',
    DEL_PRO:'全部刪除',
    CASE_CLOSED:'案件已關閉',
    TIMELINE_OWNER:'聯絡人擁有者已更改為',
    TIMELINE_NAME:'案件名稱已更改為',
    TIMELINE_CONTACT:'主要聯絡人已更改為',      
    TIMELINE_STATUS:'案件狀態已更改為',
    TIMELINE_PRIORITY:'優先等級已更改為',
    TIMELINE_GROUP:'所屬部門已更改為',      
    TIMELINE_TYPE:'案件類型已更改為',
    TIMELINE_DUE_DATE:'預期結案日已更改為',
    TIMELINE_CASE_PROCESS:"案件處理流程已更改為",
    //----Right
    PRIMARY_CONTACT_CASE:'主要聯絡人相關案件',
    NO_RELATED_CASE:'尚無其他相關案件',
    RELATED_CONTACT:'案件相關聯絡人',
    NO_RELATED_CONTACT:'尚無相關聯絡人',
    CHILD_CASE:'子案件',
    NO_CHILD_CASE:'尚無相關子案件',
    KNOWLEDGE_ARTICLE:'知識庫文章',
    NO_RELATED_ARTICLE:'尚無相關文章',
    EXPAND:'展開',
    COLLAPSE:'收起',
    REMOVE:'移除',
    ADD:'添加',
    //----MESSAGE
    MESSAGE:'您確定要刪除此備註嗎？',      
    MESSAGE_2:'您確定要刪除此案件嗎？',
    MESSAGE_3:'結案日期將會被覆蓋，請問是否仍要重啟？',      
    MESSAGE_4:'是否要與所有子案件一同結案？',      
    ERROR:'請輸入正確格式',
  },
  ADD_CASE:{
    TITLE: '新增案件',
    NAME: '案件標題',
    ORG:'選擇公司',
    CONTACT:'選擇聯絡人',
    NO_CONTACT:'請輸入聯絡人姓名進行搜尋',
    NEW_CONTACT:'建立全新聯絡人',
    OLD_CONTACT:'選擇已有聯絡人',
    PHONE:'進線電話',
    EMAIL:'進線電郵',
    SOURCR:'案件來源',
    GROUP:'案件組別',
    TYPE:'案件類型',
    PARENT_CASE:'母案件',
    CATEGORY:'案件分類',
    EXPECTED_CLOSE_DATE:'預計結案日期',
    SELECT:'請選擇案件處理流程',
    PROCESS:'案件流程',
    ERROR:'必須選擇已建立的交易流程',
    ERROR_2:'必須選擇已建立的聯絡人或點擊全新聯絡人',
    ERROR_3:'客戶與公司須至少填其一',
    ERROR_4:'請選擇已建立之案件',
    USE_AUTONUMBER:"自動填入流水號",
  },
  TO_CASE:{
    NAME: '客戶名稱',
    EXPECTED_CLOSE_DATE:'預期結案日期',
    PRIORITY:'優先等級',
    SCORE:'分數',
    STATUS:'狀態',
    OWNER:'客服人員',      
    GUIDE:'客服指引',
    BUDGET:'確認預算',      
    DETAIL_PAGE:'前往頁面',
    IN:'於',
    LOST:'失敗',
    CLOSED:'結案',
    WIN:'成功結案',
    SOLVE:'解決'
   },
   ADD_CASE_PRODUCT:{
    TITLE:'產品列表',
   },
   //------- Bulk Message
   Bulk_Message:{
    TITLE: '推播訊息',      
  },
   //------- Segment
   SEGMENT:{
    TITLE: '客戶分群',      
    //----MESSAGE
    MESSAGE:'您確定要刪除此分群嗎？',
    MESSAGE_2_1:'請先刪除',
    MESSAGE_2_2:'相關活動',
    MESSAGE_3:'請先刪除相關活動',
    //----head
    NAME:'客戶分群名稱',
    TYPE:'分群類型',
    PEOPLE:'分群人數',
    CAMPAIGNS:'相關行銷活動總數',
    OWNER:'擁有者',
    ACTIVE:'執行',
  },
  ACTIVITY:{
    ADD:'新增活動',
    TITLE:'活動',
    PRIORITY: '優先度',
    COMPLETED:'已完成',
    MARK_COMPLETED:'標記為已完成',
    REGARDING:'相關',
    TIME_FRAME:'時間區間',
    TYPE:'種類',
    OWNER:'負責人',
    SUBJECT:'主旨',
    PROGRESS:'進度',
    REMARK:'備註',
    CONTACT:'聯絡人',
    ORG:'公司',
    URGENT:'緊急',
    IMPORTANT:'重要',
    MEDIUM:'一般',
    LOW:'低',
    START_TIME:'起始時間',
    DUE_TIME:'到期時間',
    START_DAY:'起始日',
    END_DAY:'到期日',
    COMPLETED_UNCOMPLETED:'未完成',
    COMPLETED_COMPLETED:'完成',
    UNCOMPLETED_ACTIVITY:'未完成活動',
    TYPE_SETTING:'活動種類',
    TYPE_NAME:'種類名稱',
    TYPE_COLOR:'顏色',
    TYPE_ICON:'圖示',
    ADD_TYPE:'新增活動種類',
    ADD_TYPE_BTN:'新增種類',
    MESSAGE_DELETE:'您確定要刪除此活動嗎？',
    MONTH:'月份',
    WEEK:'週',
    DAY:'日',
    TODAY:"今日",
    MORE:" {0} 更多",
    MONDAY:"星期一",
    TUESDAY:"星期二",
    WEDNESDAY:"星期三",
    THURSDAY:"星期四",
    FRIDAY:"星期五",
    SATURDAY:"星期六",
    SUNDAY:"星期日",
    DELETE_ERROR:'無權限刪除以下活動:',
    NO_CONTACT:"找不到聯絡人",
    NO_ORG:"找不到公司",
    NO_REGARDING:"找不到案件或商機",
    NOT_FOUND_404:"活動不存在或已被刪除",
  },
  SEGMENT_DETAIL:{
    CONTACTS:'聯絡人總數',      
    CAMPAIGNS:'活動總數',
    TYPE:'推播類型',
    OWNER:'客戶分群擁有者',
    DESCRIPTION:'分群描述',
    INFO:'分群資訊',
    NAME:'名稱',
    RELATED_CAMPAIGN:'相關活動',
    TIME:'次',
    DELETE_CONTACT:'刪除聯絡人',
    ADD_CONTACT:'新增聯絡人',
    CAMPAIGN_Analysis_Chart:'活動分析圖表',
    NO_DATA:'尚無資料',
    CAMPAIGN_Analysis_Statistics:'活動分析統計',
    NO_CAMPAIGN_DATA:'尚無活動資料',
    //----TAB
    SETUP:'基本資料',
    CONTACT:'聯絡人',
    ANALYSIS:'分析',
    C_NAME:'聯絡人姓名',
    C_ORG:'公司名稱',
    C_PHONE:'聯絡電話',
    C_MAIL:'電子郵件',
    C_AMOUNT:'總金額',
    C_OWNER:'擁有者',
    C_ACTIVE:'執行',
    CAM_NAME:'行銷活動名稱',
    CAM_TYPE:'類型',
    CAM_TIME:'活動次數',
    S_V_S:'成功總數 vs 寄送總數',
    O_V_S:'開啟總數 vs 成功總數',
    C_V_S:'點擊總數 vs 成功總數',
    S_V_O:'成功總數 vs 開啟總數',
    S_V_C:'成功總數 vs 點擊總數',
    SUCCESS:'成功總數',
    SENT:'寄送總數',
    OPEN:'開啟總數',
    CLICK:'點擊總數',
    END_TIME:'最後發送時間',
    //----MESSAGE
    MESSAGE:'您確定要刪除此分群嗎？',
    MESSAGE_2:'您確定要刪除此聯絡人嗎？',      
    ERROR:'請先刪除相關活動',
  },
  ADD_SEGMENT:{
    TITLE: '新增分群',
    NAME:'分群名稱',
    TYPE:'分群類型',
    DESCRIPTION:'描述',
    MESSAGE:'新分群已建立，點選確認前往查看',
  },
  EDIT_SEGMENT:{
    TITLE: '修改分群',
    NAME:'分群名稱',
    TYPE:'分群類型',
    DESCRIPTION:'描述',
  },
  ADDSEGMENTCONTACT:{
    TITLE: '分群聯絡人',
    ADD_CONTACT:'確認新增聯絡人',
    FIRST_NAME:'聯絡人名稱',
    ORG_NAME:'公司名稱',      
    PHONE:'電話',
    MAIL:'電子郵件',
  },
  //------- CAMPAIGN
  CAMPAIGN:{
    TITLE: '行銷活動',  
    //----MESSAGE
    MESSAGE:'確定要刪除此行銷活動嗎？',
    MESSAGE_2:'確定要刪除這些行銷活動嗎？',      
    ERROR:'請先建立客戶分群',
    ERROR_2:'此行銷活動使用中',
    ERROR_3:'以下行銷活動使用中',
    //----head
    NAME:'行銷活動名稱',
    CHANNEL:'推播渠道',
    SEGMENT:'客戶分群',
    PEOPLE:'活動參與人數',
    OWNER:'擁有者',
    ACTIVE:'執行',
  },
  CAMPAIGN_DETAIL:{
    STATUS:'狀態',
    CHANNEL:'推播渠道',
    PEOPLE:'活動參與人數',
    SEGMENT:'客戶分群',
    OWNER:'行銷活動擁有者',
    DESCRIPTION:'活動描述',
    NAME:'名稱',
    TYPE:'活動分類',
    START_TIME:'開始日期',
    END_TIME:'結束日期',
    TEMPLATE:'選擇樣板',
    EMAIL_CONTENT:'Email內容：',
    SMS_CONTENT:'SMS內容：',
    PREVIEW:'預覽',
    SENTING:'寄送中...',
    NO_O365:'您還未設定您的M365帳號。如果要寄送電郵，請至設定>Email同步設定M365帳號。謝謝！',
    INVALID_O365_TOKEN:'您的設置M365帳號長時間未使用。請轉到設定>Email同步刪除現有的M365帳號並重新設置。謝謝！',
    GO_SETTING:'前往設定',
    SUBJECT:'主旨',
    SMS_CONTENT_2:'簡訊內容',
    CAMPAIGN_Analysis_Chart:'活動分析圖表',
    NO_DATA:'尚無資料',
    CAMPAIGN_Analysis_Statistics:'活動分析統計',
    NO_CAMPAIGN_DATA:'尚無活動資料',
    ATTACHMENT:'附件',
    //----TAB
    CAM_NAME:'行銷活動名稱',      
    END_TIME_2:'最後發送時間',
    SETUP:'基本資料',      
    ANALYSIS:'分析',
    //----MESSAGE
    MESSAGE:'確定要刪除此行銷活動嗎？',
    ERROR:'請輸入Email主旨',    
    ERROR_2:'請輸入內容',
    ERROR_3:'請先在客戶分群新增聯絡人。',
  },
  ADD_CAMPAIGN:{
    TITLE: '新增行銷活動',
    CHANNEL:'推播渠道',
    NAME:'活動名稱',
    SEGMENT:'客戶分群',
    DESCRIPTION:'活動描述',
  },
  EDIT_CAMPAIGN:{
    TITLE: '修改行銷活動',
    CHANNEL:'推播渠道',
    NAME:'活動名稱',
    SEGMENT:'客戶分群',
    DESCRIPTION:'活動描述',
  },
  CAMPAIGNCONTACTLIST:{
    TITLE: '活動紀錄聯絡人',
    ADD_SEG:'建立客戶分群',
    Success:'成功寄送',
    unSuccess:'未成功寄送',
    Open:'已開啟信件',
    unOpen:'未開啟信件',
    Link:'已點擊連結',
    unLink:'未點擊連結',
    CONTACT_NAME:'聯絡人姓名',
    ORG_NAME:'公司名稱',
    PHONE:'電話',
    MAIL:'電子郵件',
  },
  //------- KnowledgeArticles
  KNOWLEDGE_ARTICLE:{
    TITLE: '知識庫文章',
    MESSAGE:'您確定要刪除此文章嗎？',   
    //----Headers
    H_TITLE:'文章標題',
    NUMBER:'知識庫文章編號',
    STATUS:'狀態',
    UPDATED_ON:'最後更新時間',
    AUTHOR:'作者',
    ACTIVE:'執行',
  },
  KNOWLEDGE_ARTICLE_DETAIL:{
    NUMBER:'文章編號',
    SUBJECT:'文章主題',
    STATUS:'文章狀態',
    AUTHOR:'作者',
    TITLE:'標題',
    AUTONUMBER:'編號',
    SUBJECT_1:'主題',
    STATUS_2:'狀態',
    KEYWORD:'關鍵字',
    DESCRIPTIONL:'描述',  
    AUTONUMBER_1:'文章編號將自動生成',
    //-----BREADCRUMBS   
    KNOWLEDGEARTICLES:'知識庫文章',
    ADD_KNOWLEDGEARTICLES:'新增知識庫文章',  
    //----TAB
    DETAIL:'詳細資料',      
    //----MESSAGE
    MESSAGE:'您確定要刪除此文章嗎？',
    MESSAGE_2:'您確定要發布此文章嗎？',
    MESSAGE_3:'您確定要重啟編輯此文章嗎？',
    ERROR:'請輸入文章內容', 
  },
  PREFERENCES:{
    PREFERENCE:"Preference",
    ADD_PREFERENCE:"新增",
    TENANT: "租戶",
    CODE:"Code",
    VALUE:"值",
    NAME:"名稱",
    DESCRIPTION:"描述",
    ACTIVE: "執行",
    MESSAGE: "您確定要刪除此Preference嗎？",
    ERROR: "此CODE已存在。",
    ERROR1: "：格式錯誤並不是JSON",
    RULE_UPPERCASE: "必須是大寫英文字母或底線"
  },
  //------- Setting
  SETTING:{
    TITLE: '設定',
    PERSONALSETTING:'個人化設定',
    PASSWORDANDLOGIN:'登入與安全性',
    EMAILSYNC:'Email 同步',
    EMAILTEMPLATE:'Email 寄件範本',
    SMSTEMPLATE:'SMS 寄件範本',
    MANAGEUSERS:'使用者管理',
    MANAGEGROUPS:'團隊管理',
    CUSTOMFIELDS:'自定義欄位',
    TABLESETTING:'自定義資料表',
    FILTERSETTING:'自定義篩選',
    PIPELINESTAGESETTING:'商業流程設定',
    CUSTOMERSERVICESETTING:'客戶服務選項',
    KNOWLEDGEARTICLESUBJECTSETTING:'知識庫文章主題',
    AUTONUMBERSETTING:'流水號設定',
    PRODUCTSETTING:'產品設定',
    NOTIFICATIONSETTING: "公告欄設定",
    PERSONAL: "個人",
    COMMUNICATION: "溝通",
    COMPANY_SETTING: "公司設定",
    DATA_VIEW: "數據顯示",
    CUSTOMER_SERVICE_SETTING: "客戶服務設定",
    ACTIVITYTYPES: '活動種類設定',
    SMSVENDOR: 'SMS服務商設定',
    UPGPTSETTING: 'UpGPT設定',
    ASSISTANTSETTINGANDUPLOAD: 'AI助理設定',
    GPTTOOLS: '工具箱',
    UPLOADSTATUS:'匯入/上傳紀錄',
    TREESETTING: '樹狀選項設定',
    CALENDARSYNC:"行事曆同步",
    CUSTOMOPPTYCLOSEDREASON: "自定義商機結案原因",
  },
  CUSTOMOPPTYCLOSEDREASON:{
    ADD:"新增原因",
    ADDREASON:"新增商機結案原因",
    NAME:"結案原因",
    DESCRIPTION:"原因描述",
    SELECTED:"啟用",
    ACTIVE:"執行",
  },
  TREESETTING:{
    ADD:'新增選項',
    TS:'樹狀選項',
    DEL_MSG:'您確定要刪除此選項嗎?',
    OPEN_ALL:'全部展開',
    CLOSE_ALL:'全部收合',
    NO_OPTION:'至少須有一個選項',
    ERROR:'這選項名稱已存在。',
    IS_USING:'此選項使用中，不可刪除',
    NO_DATA:'請先新增樹狀選項',
  },
  UPGPT:{
    BASICSETTING: '基礎設定',
    QAUPLOAD: 'Q&A資料上傳',
    FOLDERUPLOAD: '資料夾上傳',
    FILEUPLOAD: '檔案上傳',
    UPLOADIMG: '圖片上傳',
    CLOUD: '雲端檔案',
    BASICSETTINGSAVE: '儲存',
    AIASSISTANTNAME: 'AI助理名稱',
    AIASSISTANTNAME_TIP1: '設定您AI助理的名稱',
    AIASSISTANTNAME_TIP2: '範例: 小安',
    AIASSISTANTROLE: 'AI助理角色',
    AIASSISTANTROLE_TIP1: '設定您AI助理所扮演的角色',
    AIASSISTANTROLE_TIP2: '範例: customer service agent (客服人員) for UpDay, a CRM production designed by 安然科技股份有限公司',
    TOPIC: '主題',
    TOPIC_TIP1: '提供AI助理一個主題，使其能更好的改寫提問',
    TOPIC_TIP2: '範例: UpDay CRM',
    TOPICLIMIT: '主題限制',
    TOPICLIMIT_TIP1: '如果問題超出主題,AI助理會回到應有的主題範圍回覆',
    TOPICLIMIT_TIP2: '範例: technology',
    RESETDATA: '清空現有知識',
    QAFILEUPLOADMESSAGE: 'Q&A檔案需為CSV檔(UTF-8)',
    FOLDERUPLOADMESSAGE: '僅接受壓縮檔',
    FILEUPLOADMESSAGE: '僅接受 txt、pdf、docx 及 pptx檔',
    DELETEALLDATA: '你確定要重置所有既有資訊?',
    WRONGTYPEINZIP: '壓縮檔內只能有txt、CSV、pdf、docx、pptx、jpg、jpeg & png檔',
    WRONGTYPEINCSV: 'Q&A檔應為CSV檔(UTF-8)',
    WRONGTYPEINFILE: '檔案上傳僅接受 txt、pdf、docx 及 pptx檔',
    WRONGTYPEINIMAGEFILE: '圖片上傳僅接受 JPG、JPEG、PNG 檔',
    WAIT: '請等待檔案上傳完成後再試一次',
    PREFERENCE_WRONG: '權限設定不完整，請聯繫管理者',
    AIASSISTANT_KNOWLEDGE_MANAGEMENT: 'AI助理知識管理',
    FILE_AMOUNT: '知識庫檔案總數: ',
    UPDATE_TIME: '最近一次上傳時間: ',
    NOFILE: '知識庫沒有任何檔案',
    UPCHAT_INBOX_SETTING: 'UpChat 收件匣設定',
    TIMEOUT_ERROR:'因數據量較多，AI助理仍在將其整理成知識庫，是否前往"匯入/上傳紀錄"檢視進度?',
    EMBEDDING_WRONG:'上傳失敗，請檢查您的CSV檔為UTF-8編碼、標頭(第一行)的所有欄位是否出現雙引號(如"XXX")的情形，請去除後再重新上傳。也請您檢查您的檔案是否被加密，請移除加密後在重新上傳，如果還是未能上傳，請聯繫UpDay客服人員，謝謝您!',
    UPLOAD_FAIL:'上傳失敗',
    FILE: '檔案',
    DOWNLOAD: '下載',
    DOWNLOAD_TXT: '下載文字檔',
    FILE_HAS_BEEN_DELETED: '檔案已被刪除，請重整頁面',
    WEBSITEANALYSIS: '網站分析',
    ADD_URL: '新增網址',
    EDIT_URL: '編輯網址',
    URL: '網址',
    LEVEL: '深度',
    OVERLIMIT: '網址最多只能填寫5個',
    ACTION: '執行',
    DELETE_CHECK: '你確定要刪除這個網址嗎?',
    START_ANALYSIS: '開始分析',
    DOWNLOAD_ZIP: '下載壓縮檔',
    BLANK_WEB_INFO: '您需要先進行過分析，才能下載結果',
    NO_URL:'請先填入至少一個網址',
    FILE_TABLE: '已連結知識庫檔案',
    NOT_CLEAN: '未正確清空，請再清空一次，若還是未能清空，請聯繫UpDay客服人員，謝謝您!',
    AUTO_DETECT: '自動偵測',
    REPLY_LANGUAGE: '回覆語言',
    REPLY_LANGUAGE_TIP: 'AI助理會依據所選擇的語言回覆',
  },
  ACTIVITYTYPES:{
    ERROR:"此活動類型使用中，不可刪除。",
    ERROR_2:"至少須有一個活動類型。",
    ERROR_3:"預設活動類型不可刪除。"
  },
  SMSVENDOR:{
    VENDOR:"服務商",
    USER_NAME:"使用者名稱",
    USER_PWD:"使用者密碼",
    DEFAULT:"預設",
    MITAKE:"三竹Mitake",
    DOMAIN:"單筆簡訊發送 API URL",
    TIP:"請參考三竹給您的API文件",
    TIP_2:"例 : https://smsapi.mitake.com.tw/api/mtk/SmSend?CharsetURL=UTF8",
  },
  PERSONALSETTING:{
    BOARD_CONTACT_SYNC:'從經濟部商業司同步董監事資料為聯絡人',
    FIRST_NAME:'使用者名稱',
    LAST_NAME:'使用者姓氏',
    LANGUAGE:'語言',
    CURRENCY:'幣別',
    REGION:'地區',
    OPPTY_DEFAULT_STAGE:'商機預設流程',
    CASE_DEFAULT_STAGE:'案件預設流程',
    INBOX_NOTIFICATION_SETTINGS:'Inbox 通知設定',
    ERROR:'請選擇圖檔',
    ERROR_2:'照片請小於5MB',
  },
  PASSWORDANDLOGIN:{
    TITLE: '變更密碼',
    TITLE_2: '重設密碼',
    MESSAGE:'為了您的帳戶安全，請定時更改密碼。並增加密碼複雜程度，不要使用容易猜測的字串。例：生日、電話...等',
    ERROR:'請確認密碼長度為8-30個字且包含大寫小寫英文字母、數字、特殊符號',
    ERROR_2:'輸入密碼不符合新密碼，或密碼格式錯誤',
    ERROR_3:'請填入目前密碼',
    ERROR_4:'新密碼和確認密碼為必填',
    ERROR_5:'請重新確認輸入密碼',
    ERROR_6:'修改密碼失敗, 請重試',
    ERROR_7:'新密碼和確認密碼不同, 請重新輸入',
    ERROR_8:'重設密碼失敗, 請重試.  厡因:',
    ERROR_9:'此重設密碼連結已失效',
    OLD_PASSWORD:'目前密碼',
    NEW_PASSWORD:'新密碼',
    NEW_PASSWORD_2:'確認密碼',
  },
  EMAILSYNC:{
    TEXT:'連結帳戶',
    TEXT_2:'以下顯示您已連結之M365帳戶',
    NO_CONNECT:'尚無連結帳戶',
    ADD_ACCOUNT:'新增帳戶',
    MESSAGE:'您確定要刪除此M365帳號設定嗎？',
    ERROR:'刪除失敗, 請重試',
    DIALOG:'連結M365帳戶',
    DIALOG_2:'點擊新增按鈕，將為您導至M365登入頁面。連結成功後，將為您導回此設定頁面。',
    DIALOG_S:'成功連結M365帳戶',
    DIALOG_S2:'您已成功連結M365帳戶。請在電子郵件同步部分驗證您的帳戶電子郵件地址。謝謝！',
    START_SYNC:"開始同步",
    SYNCING:"同步中",
    H1:"Email同步處理：",
    H1_1:"所有Email皆同步至UpDay",
    H1_2:"只有指定電郵同步至UpDay",
    H1_3:"開啟",
    H1_4:"關閉",
    H2:"從何時開始同步：",
    H2_1:"今天",
    H2_2:"1個月內",
    H2_3:"3個月內",
    H2_4:"6個月內",
    H2_5:"自訂",
    H3:"同步寄送與寄出的Email對象：",
    H3_1:"只有UpDay聯絡人",
    H3_2:"所有人",
    H4:"Email 限制設定：",
    H5:"設定黑名單Email或網域",
    H6:"黑名單Email",
    H7:"黑名單網域",
    H8:"可接受網域",
    TOO_MUCH_INBOX:"收件匣中的信件數量超過9999，請選擇較少同步時間範圍後再試一次。",
    TOO_MUCH_SENTBOX:"寄件匣中的信件數量超過9999，請選擇較少同步時間範圍後再試一次。",
  },
  EMAILTEMPLATE:{
    TEMPLATE:'範本',
    ADD_TEMPLATE:'新增範本',
    EDIT_EMAIL_TEMPLATE:'修改Email範本',
    ADD_EMAIL_TEMPLATE:'新增Email範本',
    NAME:'範本名稱',
    SUBJECT:'主旨',
    //Header
    ADD_DATE:'建立時間',
    END_TIME:'最後修改時間',
    ACTIVE:'執行',
    MESSAGE:'您確定要刪除嗎？',
    ERROR:'請填入新增範本名稱!',
  },
  SMSTEMPLATE:{
    TEMPLATE:'範本',
    ADD_TEMPLATE:'新增範本',
    EDIT_SMS_TEMPLATE:'修改SMS範本',
    ADD_SMS_TEMPLATE:'新增SMS範本',
    NAME:'範本名稱',
    //Header
    ADD_DATE:'建立時間',
    END_TIME:'最後修改時間',
    ACTIVE:'執行',
    MESSAGE:'您確定要刪除嗎？',
  },
  MANAGEUSERS:{
    SEAT:'可用空位',
    ACTIVATED:'啟用',
    UNACTIVATED:'未啟用',
    INVITE_USERS:'邀請使用者',
    MANAGER:'管理者',
    STAFF:'員工',
    MAIL:'Email (將作為登入帳號使用)',
    FIRST_NAME:'使用者名稱',
    LAST_NAME:'使用者姓氏',
    USER_ROLE:'使用者角色',
    INVITE:'送出邀請',
    EDIT_USER:'編輯使用者',
    //Header
    NAME:'名稱',
    ROLE:'角色',
    IS_SERVICE_AGENT:'客服人員',
    LAST_TIME:'最後登入時間',
    ACTIVE:'執行',
    MESSAGE:'您確定要停用此用戶嗎？',
    MESSAGE_2:'您確定要重啟此用戶嗎？',
    ERROR:'抱歉，使用者人數已達上限！如需新增，請將現有聯絡人設為未啟用。',
    ERROR_2:'抱歉，您的權限不足！',
    ERROR_3:'帳號已存在。',
    ERROR_4: '至少需有一位管理者',
  },
  MANAGEGROUPS:{
    ADD_GROUP:'新增團隊',
    GROUP:'團隊',
    NAME:'名稱',
    TYPE:'類型',
    MEMBER:'成員',
    //Header
    ACTIVE:'執行',
    ERROR:'抱歉，您的權限不足！',
    ERROR_2:'這團隊名稱已存在。',
    MESSAGE:'您確定刪除要此團隊嗎？',
  },
  CUSTOMFIELDS:{
    SELECT_PROCESS:'選擇流程',
    SELECT_PAGE:'選擇頁面',
    ADD_FIELD:'新增欄位',
    EDIT_OPTION:'編輯選項',
    OPTION:'選項',
    TREE_OPTION:'樹狀選項',
    ADD_CF:'新增自定義欄位',
    P_SELECT_PAGE:'請選擇使用頁面',
    SELECT_O_PROCESS:'選擇商業流程',
    SELECT_C_PROCESS:'選擇案件流程',
    SELECT_MESSAGE: '選擇一個或多個欄位至表單',
    NAME:'欄位名稱',
    TYPE:'欄位類型',
    DESCRIPTIONS: '欄位描述',
    MANDATORY:'是否必填',
    //Header
    ACTIVE:'執行',
    NO_OPPTY_STAGE:'尚無商業流程',
    NO_CASE_STAGE:'尚無案件流程',
    ERROR:'請至少保留兩個選項',
    ERROR_2:'請輸入正確內容',      
    ERROR_3:'請至少輸入兩個選項',
    ERROR_4:'請先新增商業流程',
    ERROR_5:'請先新增案件流程',
    ERROR_6:'請輸入新選項內容，並按下Enter',
    MESSAGE:'您確定要刪除嗎？',
  },
  TABLESETTING:{
    PAGE:'選擇頁面',
    PROCESS:'選擇流程',
    NEW:'新增資料表',
    COPY: '複製當前資料表',
    DEFAULT:'預設',
    NAME:'名稱',
    MESSAGE:"您確定要刪除此資料表嗎？",
    SET:'設定',
    FIELD:'欄位',
    SEQ:'排序',
    TITLE:'編輯當前資料表',
  },
  FILTERSETTING:{
    FILTER:'篩選',
    ADD:"新增篩選",
    EDIT:"編輯篩選",
    COPY: "複製篩選",
    VISIBILITY:"隱私設定",
    VISIBILITY_0:"私人",
    VISIBILITY_1:"公開",
    DEFAULT: '預設',
    FILTERFIELD:"選擇欄位",
    PERSONAL_SETTING: '個人設定',
    SYSTEM_SETTING: '系統設定',
    CONDITION:"選擇條件",
    ADD_CONDITION:"新增條件",
    DATE:"選擇日期",
    CAMPAIGN: '選擇相關活動',
    SEG:'選擇相關分群',
    PRODUCT:'選擇產品',
    PARENT_CASE: '選擇母案件',
    TEXT:"填入文字",
    NUM:"填入數字",
    OPT:"選擇選項",
    TRUE:"是",
    FALSE:"否",
    INPUT_TO_SEARCH: '請輸入公司名稱進行搜尋',
    INPUT_CONTACT_TO_SEARCH: '請輸入聯絡人名稱進行搜尋',
    INPUT_PRODUCT_TO_SEARCH: '請輸入產品名稱進行搜尋',
    INPUT_PARENT_CASE_TO_SEARCH: '請輸入母案件名稱進行搜尋',
    ERROR: '請至少新增一項條件',
    ERROR2: '請選擇欄位',
    ERROR3: '請確認搜尋條件',
    MESSAGE: '您確定要刪除此篩選嗎？',
    PUBLIC:'公開',
    PRIVATE:'私人',
    CS:'自定義選項',
    NO_FILTER:'無篩選',
    DELETED_DATA: '已被刪除的資料',
  },
  OP_TEXT:{
    CONTAINS:"包含",
    NOT_CONTAINS:"不包含",
    IS_EMPTY:"為空",
    NOT_EMPTY:"不為空",
  },
  OP_NUM:{
    EQUAL:"等於",
    EQUAL_LESS:"小於等於",
    EQUAL_LARGER:"大於等於",
    LESS:"小於",
    LARGER:"大於",
  },
  OP_DATE:{
    ON:"等於",
    ON_OR_AFTER:"等於 或 之後",
    ON_OR_BEFORE:"等於 或 之前",
    TODAY:"今天",
    YESTERDAY:"昨天",
    TOMORROW:"明天",
    NEXT_7_DAYS:"接下來7天",
    LAST_7_DAYS:"之前7天",
    THIS_WEEK:"本周",
    THIS_MONTH:"本月",
    THIS_QUARTER:"本季",
    THIS_YEAR:"今年",
    NEXT_WEEK:"下周",
    NEXT_MONTH:"下個月",
    NEXT_QUARTER:"下一季",
    NEXT_YEAR:"明年",
    LAST_WEEK:"上周",
    LAST_MONTH:"上個月",
    LAST_QUARTER:"上一季",
    LAST_YEAR:"去年",
  },
  PIPELINESTAGESETTING:{
    ADD:'新增流程',
    WORRY:'不用擔心您的團隊沒有按照商業流程或是SOP！只要您在此設定好商業流程，系統將會引導您的團隊邁向更好的結果。',
    ERROR:'此流程已有商機使用，請更換流程後再行刪除。',
    ERROR_2:'此流程已有案件使用，請更換流程後再行刪除。',
    MESSAGE:'您確定要刪除嗎？',
    DEFAULT_PROCESS: '系統預設流程'
  },
  CUSTOMERSERVICESETTING:{
    ADD:'新增選項',
    TYPE:'案件類型',
    GROUP:'案件組別',      
    CATEGORY:'案件分類',   
    ADD_OPTION:'新增客戶服務選項',
    USEING_TYPE:'請選擇使用類型',
    NAME:'選項名稱',
    OPTION_DESCRIPTION:'選項描述',
    //----TAB
    ACTIVE:'執行',
    MESSAGE:'您確定要刪除嗎？',
    ERROR_1:"此案件組別正在使用中",
    ERROR_2:"此案件類型正在使用中",
  },
  KNOWLEDGEARTICLESUBJECTSETTING:{
    ADD_SUBJECT:'新增主題',
    ADD:'新增知識庫文章主題',
    NAME:'主題名稱',
    DESCRIPTION:'主題描述',
    ACTIVE:'執行',
    MESSAGE:'確定要刪除此主題嗎？',
  },
  AUTONUMBERSETTING:{
    CASE_SETTING:'案例設定',
    PERFIX:'前綴字元',
    SUFFIX:'後綴字元',
    RECODR_TYPE:'紀錄類型',
    NUMBER_FORMAT:'數字格式',    
    START_NUMBER:'起始號碼',
    CURRENT_NUMBER:'目前號碼',
    RESET:'重置目前號碼',
    ACTIVATED:'啟用',
    UNACTIVATED:'停用',
    KA_SETTING:'知識文章設定',
    TIME_MODE:'時間模式',
    NUMBER_MODE:'數字模式',
    NO_RECORD:'尚無紀錄',
    MESSAGE:'目前號碼小於起始號碼，是否重置目前號碼？',
    RULE:'字數請限制在',
    RULE_1:'字內',
    AUTO:'自動填入標題',
  },
  PRODUCTSETTING:{
    PRODUCT:'產品',
    CATEGORY:'類別',
    SERIES:'系列',
    NAME:'名稱',
    PRODUCTCODE:'序號',
    PRICR:'定價',
    COST:'成本',
    DESCRIPTIONL:'描述',
    ADD_PRODUCT:'新增產品',
    EDIT_PRODUCT:'修改產品',
    ADD_PRODUCT_2:'新增產品類別',
    EDIT_PRODUCT_2:'修改產品類別',
    ADD_PRODUCT_3:'新增產品系列',
    EDIT_PRODUCT_3:'修改產品系列',
    MESSAGE:'您確定要刪除嗎？',
    ERROR:'此商品正在使用中',
    ERROR_2:'此類別正在使用中',
    ERROR_3:'此系列正在使用中',
  },
  NOTIFICATIONSETTING: {
    ADD_NOTICE: "新增",
    NOTICE: "公告",
    NOTICEBOARD: "公告欄",
    TITLE: "標題",
    CONTENT: "內容",
    STATUS: "狀態",
    ACTIVE: "執行",
    MESSAGE: "您確定要刪除此公告嗎？",
    MESSAGE2: "您確定嗎？下一次登入將再次顯示。",
    ACTIVATED: "啟用",
    UNACTIVATED: "停用",
    ERROR: "此公告標題已存在。",
    TIMER:"公告轉動時間",
    SECONDTIMES:"秒/次"
  },
  INBOX_NOTIFICATION_SETTINGS: {
    ALLOW_WEB_PUSH: "允許開啟網頁推播",
    ALLOW_FOLLOW: "在我追蹤的紀錄變更時提醒我",
    ALLOW_MENTION: "在我被@提及時提醒我",
    ALLOW_REPLY: "在我訂閱的貼文有人回覆時提醒我",
    ALLOW_EMOJI: "在我訂閱的貼文有人喜歡時提醒我",
  },
  CALENDAR_SYNC_SETTINGS: {
    SELECT_CALENDAR: "選擇行事曆",
    SYNC_TYPE: "同步模式",
    SYNC_AS: "同步為",
    PRIVATE_EVENTS: "私人活動",
    SELECT_TO_SYNC: "要同步的活動類型",
    SYNC_REGARDING:"同步相關資料",
    PRIVATE_EVENTS_0:"不同步",
    PRIVATE_EVENTS_1:"同步",
    SYNC_REGARDING_0:"不包含相關資料",
    SYNC_REGARDING_1:"包含相關資料",
    NO_SELECT_TYPE:"尚未選擇",
    STOP_SYNC:"停止同步",
    CONNECT_SUCCESS:"您已成功連結M365帳戶。請在行事曆同步頁面驗證您的帳戶電子郵件地址。謝謝！",
    SYNC_TYPE_ONE_0:"單向同步",
    SYNC_TYPE_ONE_1:"UpDay活動同步至M365行事曆",
    SYNC_TYPE_ONE_2:"僅更改UpDay活動同步至UpDay活動",
    SYNC_TYPE_TWO_0:"雙向同步",
    SYNC_TYPE_TWO_1:"UpDay活動同步至M365行事曆",
    SYNC_TYPE_TWO_2:"M365行事曆同步至UpDay活動",
  },
  //--------Common DIALOG
  CHANGEOWNER:{
    TITLE:'變更客服人員',
    TITLE_2:'變更文章作者',
    TITLE_3:'變更擁有者',      
  },
  CASECARD:{
    TITLE:'案件',
    NO_CASE:'尚無案件',
    CASE_NUMBER:'案件編號',
  },
  PRODUCTTABLE:{
    NO_PRO:'尚無產品',
    MESSAGE:'您確定要刪除此產品嗎？',
  },
  PRODUCTLINEITEM:{
    QUANTITY:'數量',
    QUANTITY_H:'數量',
    QUANTITY_D:'總數量',
    DAYS:"天數",
    TAX:'稅',
    TAX_H:'稅',
    UNIT:'單位',
    DISCOUNT:'折扣',
    DISCOUNT_H:'折扣',
    AMOUNT:'金額',
    AMOUNT_H:'金額',
    SUBTOTAL_LIST_PRICE:'定價小計',
    TOTAL_DISCOUNT:'折扣小計',
    SUBTOTAL_AMOUNT:'金額小計',
    SALES_TAX:'銷售稅額',
    TOTAL:'總計 ',
    TOTAL_PRODUCTS:'商品總數',
    TAX_INCLUSIVE:'金額含稅',
    TAX_EXCLUSIVE:'金額未稅',
    NO_TAX:'不計稅',
    SYSTEM_COMPUTE:'系統計算',
    MANUAL_INPUT:'手動輸入',
    DISCOUNT_DATA:'折扣',
    TAX_DATA:'稅額',
    OTHER_CHARGES:'其他費用',
    ENABLE_TRQ:'時間區間',
    FROM:'起始日期',
    TO:'結束日期',
    FROMTOERROR:'不可只填單一日期',
    OC_DISCOUNT:'其他費用小計',
    OC:'其他費用',
  },
  CASETABLE:{      
    NO_CASE:'尚無案件', 
    MESSAGE:'您確定要刪除此案件嗎？',
    SUBJECT:'主題', 
    CONTACT:'聯絡人',
    PRIORITY:'優先等級',
    STATUS:'狀態',
    GROUP:'組別',
    TYPE:'類型',
    OWNER:'客服人員',
    CLOSED_DATE:'結案日期',
  },
  OPPTYCARD:{
    TITLE:'商機',
    NO_OPPTY:'尚無商機',
    EXPECTED_CLOSE_DATE:'預期結案日期',
  },
  CUSTOMFIELD:{
    TITLE:'自定義欄位',
    NO_DATA:'尚無自定義欄位',      
  },
  EMAILEDITOR:{
    RECIPIENT:'收件人',
    COPY:'副本',
    ATTACHMENT:'附件',
    SUBJECT:'主旨',
    SEND:'寄出',
    CLEAR:'清除',
    TEMPLATE:'Email 範本',
    NO_O365:'您還未設定您的M365帳號。如果要寄送電郵，請至設定>Email同步設定M365帳號。謝謝！',
    INVALID_O365_TOKEN:'您的設置M365帳號長時間未使用。請轉到設定>Email同步刪除現有的M365帳號並重新設置。謝謝！',
    NO_DATA: '尚無資料',
    GO_SETTING:'前往設定',
    ERROR:'附件檔案總共不能超過20MB',
    ERROR_2:'此電郵地址格式不正確',
    ERROR_3:'寄送電郵之前請先填妥欄位',
  },
  SMSEDITOR:{
    ADD: '新增',
    RECIPIENT:'收件人',
    NUMBER:'收件號碼',
    ENTER:'輸入手機號碼',
    SEND:'傳送',
    CLEAR:'清除',
    TEMPLATE:'SMS 範本',
    ERROR:'寄送簡訊之前請先填妥欄位',
    ERROR_2:'請輸入正確格式',
  },
  LOSEREASON:{
    TITLE:'客服案件結案',
    TITLE_2:'選擇失敗原因',
    TITLE_3:'確認贏得商機',
    CASE_NAME:'案件名稱',
    CONTACT_NAME:'主要聯絡人名稱',
    OPPTY_NAME:'商機名稱',
    CUSTOMER_NAME:'客戶名稱',
    CLOSE_DATE:'結案日期',
    CLOSE_REASON:'結案原因',
    STATUS:'狀態',
    CLOSED:'已結案',
    OPEN:'進行中',
    AMOUNT:'金額',
    LOSE:'失敗原因',
    WIN:'贏得商機',
  },
  EMAILCAMPAIGNDIALOG:{
    TITLE:'Email 預覽',
    SUBJECT:'主旨',
    ATTACHMENT:'附件',
    MESSAGE:'Email寄送中，點選確認前往活動頁面查看寄送進度'
  },
  SMSCAMPAIGNDIALOG:{
    TITLE:'SMS 預覽',
    SMS_CONTENT:'簡訊內容',
    MESSAGE:'簡訊寄送中，點選確認前往活動頁面查看寄送進度'
  },
  ADDPROCESSDIALOG:{
    EDIT:'編輯流程',      
    ADD_OPPTY:'新增商業流程',
    ADD_CASE:'新增案件流程',
    NAME:'流程名稱',
    PAGE:'流程使用頁面',
    STAGE_NAME:'階段名稱',
    PROBABILITY:'商機贏得機率',
    FINISH_DAY:'預期完成天數',
    GUIDE:'成功指引',
    COLOR:'顏色',
    DAY:'天',
    ERROR:'階段不可為空',
    ERROR_2:'此步驟已有使用紀錄，故無法刪除。',
    MESSAGE:'您確定要刪除步驟嗎？'
  },
  ADDNOTE:{
    TITLE:'新增備註',
    TITLE_2:'修改備註',
    CONTENT:'請輸入備註內容',
  },
  EMAILATTACHMENTSDIALOG:{
    TITLE:'電郵附件',
  },
  EMAILCONTACTSDIALOG:{
    TITLE:'關聯聯絡人',
  },
  EMAILOPPTIESDIALOG:{
    TITLE:'關聯商機',
    NO_OPPTY:'尚無關聯商機',
    EXPECTED_CLOSE_DATE:'預期結案日期 : ',
  },
  REDIRECTDIALOG:{
    NEW_CONTACT:'查看新聯絡人',
    NEW_ORG:'查看新公司',
    NEW_OPPTY:'查看新商機',
    NEW_CASE:'查看新案件',
  },
  //------- List.js
  LEVELLIST:{0:"職員",1:"經理",2:"董事",3:"C-Level",4:"所有者"},
  KASTATUSLIST:{
    PUBLISHED:'已發布',
    WAIT_PUBLISH:'等待發布',
    IN_REVIEW:'審查中',
    AUTHORING:'撰寫中',
  },
  CASESTATUSLIST:{
    OPEN:'進行中',
    PENDING:'待辦中',
    WAIT_CUSTOMER:'等待客戶',
    WAIT_3RD_PARTY:'等待第三方',
    RESOLVED:'已解決',
    CLOSED:'關閉',
  },
  OPPTYSTATUSLIST:{
    OPEN:'進行中',
    WON:'贏得商機',
    LOSE:'商機失敗',
  },
  CAMPAIGNTYPELIST:{
    ADVERTISEMENT:'廣告',
    DIRECT_MARKETING:'直接營銷',
    MARKETING_EVENT:'事件營銷',
    JOINT_MARKETING:'合作營銷',
    OTHERS:'其他',
  },
  CASEPRIORITYLIST:{
    VERY_HIGH:'最高',
    HIGH:'高',
    MEDIUM:'中',
    LOW:'低',
    VERY_LOW:'最低',
  },
  CASESOURCELIST:{
    EMAIL:'電郵',
    WEB_FORM:'網路表單',
    WEB_CHAT:'線上客服',
    PHONE:'電話',      
    STORE:'實體店',
    OUTBOUND:'外撥',
    OTHERS:'其他',
  },
  CLOSECASEREASONLIST:{
    RESOLVED:'解決',
    ALTERNATIVE:'替代解決方案',
    NO_SOLUTION:'無解決方案',
    NO_RESPONSE:'客戶無回應',
    NOT_AN_ISSUE:'非問題',
  },
  CLOSEREASONLIST:{
    null: '進行中商機',
    0:'重啟商機',
    1:'贏得商機',
    2:'搶輸競爭對手',
    3:'對方不做事',
  },   
  NUMBERTYPELIST:{
    3:'三位數',
    4:'四位數',
    5:'五位數',
    6:'六位數',
    7:'七位數',
    8:'八位數',
    9:'九位數',
    10:'十位數',
    11:'十一位數',
    12:'十二位數',
    13:'十三位數',
    14:'十四位數',
    15:'十五位數',
  },
  SEGMENT_TYPELIST:{
    STATIC:'固定分群',
    DYNAMIC:'動態分群',
  },
  STAGEDEFCOLORLIST:{
    0:'紅色',
    1:'橘色',
    2:'黃色',
    3:'綠色',
    4:'藍色',
    5:'紫色',
  },
  INDUSTRYLIST:{
    0:'農、林、漁、牧業',
    1:'礦業及土石採取業',
    2:'製造業',
    3:'電力及燃氣供應業',
    4:'用水供應及污染整治業',
    5:'營建工程業',
    6:'批發及零售業',
    7:'運輸及倉儲業',
    8:'住宿及餐飲業',
    9:'出版影音及資通訊業',
    10:'金融及保險業',
    11:'不動產業',
    12:'專業、科學及技術服務業',
    13:'支援服務業',
    14:'公共行政及國防；強制性社會安全',
    15:'教育業',
    16:'醫療保健及社會工作服務業',
    17:'藝術、娛樂及休閒服務業',
    18:'其他服務業',
  },
  ORGTYPELIST:{
    0:'外國公司之分公司',
    1:'外國公司之辦事處',
    2:'本地公司設立之分公司',
    3:'合作社',
    4:'合夥',
    5:'有限公司',
    6:'有限合夥',
    7:'兩合公司',
    8:'其他',
    9:'股份有限公司',
    10:'無限公司',
    11:'獨資',
  },
  AGENTSTATUSLIST:{
    ONLINE:'線上',
    AWAY:'離開',
    BUSY:'忙碌',
    OFFLINE:'離線',
  },
  CONTACTSFIELDLIST:{
    created_on:'建立時間',
    created_by:'建立者',
    updated_on:'更新時間',
    updated_by:'更新者',
    owner:'擁有者',
    orgs:'公司名稱',
    full_name:'聯絡人姓名',
    total_amount:'進行商機總額',
    title:'職稱',
    email:'電子郵件',
    mobile_phone:'行動電話',
    office_phone:'連絡電話',
    description:'描述',
    department:'部門',
    birthdate:'生日',
    level:'級別',
    fax:'傳真',
    facebook:'Facebook',
    linkedin:'Linkedin',
    instagram_id:'Instagram',
    line_id:'Line',
    billing_address:'地址',
    city:'城市',
    country:'國家',
    zip_code:'郵遞區號',
    province:'省分',
    primary_campaign_id:'相關活動',
    seg:'分群'
  },
  ORGSFIELDLIST:{
    created_on:'建立時間',
    created_by:'建立者',
    updated_on:'更新時間',
    updated_by:'更新者',
    owner:'擁有者',
    name:'公司名稱',
    website:'網站',
    company_number:'統一編號',
    main_phone:'電話',
    description:'描述',
    industry:'行業別',
    employee:'員工人數',
    establish_date:'成立日期',
    annual_revenue:'營業額',
    registered_capital:'資本額',
    fax:'傳真',
    billing_address:'地址',
    city:'城市',
    country:'國家',
    zip_code:'郵遞區號',
    province:'省分',
    total_amount:'進行商機總額',
    organization_type:'類型',
    primary_campaign_id:'相關活動',
  },
  OPPTYSFIELDLIST:{
    created_on:'建立時間',
    created_by:'建立者',
    updated_on:'更新時間',
    updated_by:'更新者',
    owner:'商機擁有者',
    name:'商機名稱',
    amount:'金額',
    expected_close_date:'預期結案日期',
    closed_date:'結案日期',
    closed_reason:'結案原因',
    status: '狀態',
    forecast_category:'預測類別',
    customer_needs:'客戶需求',
    customer_budget:'客戶預算',
    budget_confirmed:'確認預算',
    primary_campaign_id:'相關活動',
    stage_type:'商業流程',
    oppty_contact:'客戶名稱',
    current_stage:'現在階段',
    primary_contact:'主要聯絡人',
    description:'描述',
    filter_oppty_contact: '客戶名稱（聯絡人）',
    filter_oppty_org: '客戶名稱（公司）',
    tax_option:'計稅方式',
  },
  CASESFIELDLIST:{
    created_on:'建立時間',
    created_by:'建立者',
    updated_on:'更新時間',
    updated_by:'更新者',
    owner:'客服人員',
    subject:'標題',
    case_number:'案件編號',
    due_date:'預期結案日',
    incoming_fullname:'進線聯絡人',
    incoming_phone:'進線電話',
    incoming_email:'進線電郵',
    source:'案件來源',
    status:'狀態',
    closed_date:'結案日期',
    closed_reason:'結案原因',
    priority:'優先等級',
    primary_campaign_id:'相關活動',
    primary_contact:'聯絡人',
    case_org:'公司',
    group:'組別',
    type:'類型',
    stage_type:'案件流程',
    current_stage:'階段',
    description:'案件描述',
    resolution:'案件處理',
    product:'產品',
    parent_case_id: '母案件',
  },
  CASEDASHBOARD: {
    TITLE: '客服案件儀表板',
    UNSOLVED: '未解決',
    OVERDUE: '過期',
    DUE_TODAY: '今日到期',
    CLOSED: '已結案',
    OPEN: '進行中',
    ON_HOLD: '等待中',
    HIGH_PRIORITY: '高優先等級',
    AVERAGE_RESOLVED_TIME: '平均處理時間',
    DAY: '天',
    HOUR: '小時',
    MIN: '分鐘',
    PRIORITY: '優先等級',
    SOURCE: '進線來源',
    GROUP: '組別',
    TYPE: '類型',
    PROCESS: '處理流程',
    LINE_CHART: '案件折線圖',
    RECEIVED: '受理案件',
    RESOLVED: '解決案件',
    CASE_RANKING: '案件處理排行',
    CASES: '件',
    UP: '上升',
    DOWN: '下降',
    AGENT_STATUS: '客服人員狀態',
    AGENT_TOTAL: '客服人員總數'
  },
  UPCHATDASHBOARD:{
    TITLE: '聊天互動儀表板',
  },  
  CAMPAIGNSTAUSLIST:{
    PROPOSED:'已提案',
    READY_TO_ACTIVATE:'準備啟動',
    ACTIVATED:'已啟動',
    COMPLETED:'已完成',
    CANCELLED:'已取消',
    SHELVED:'擱置',
    NOT_IN_USE:'非使用中',
  },
  IMPORT_EXPORT:{
    TITLE:'匯入資料',
    TITLE_2:'匯出資料',
    IMPORT:'匯入',
    EXPORT:'匯出',
    PAGE:'頁面',
    DATATYPE:'匯出資料',
    STAGE:'流程',
    DOWNLOAD:'下載',
    TEMP:'範本',
    DB_DATA:'所有資料',
    SELECT:'選擇檔案',
    NOTICE:'注意事項',
    DOWNLOAD_TEMP:'下載匯入範本',
    ERROR:'本瀏覽器不支援匯出功能, 建議使用Chrome或Edge',
    CONTACT_LABEL:'注意事項\n1. 若有填入ID欄位，資料將會更新\n2. 填入Owner欄位時，姓名中間請以空格分開，如：陳 振凱\n3. Company Name、Campaign欄位內容不存在時，將自動建立\n4. 自定義欄位名稱若有重複，或與現有欄位名稱相同時，資料會被覆蓋\n5. 資料內容出現科學記號時，請於Excel中調整儲存格格式，避免資料儲存錯誤\n6. 填入日期時，請以yyyy-mm-dd格式輸入',
    ORG_LABEL:'注意事項\n1. 若有填入ID欄位，資料將會更新\n2. 填入Owner欄位時，姓名中間請以空格分開，如：陳 振凱\n3. Campaign欄位內容不存在時，將自動建立\n4. 自定義欄位名稱若有重複，或與現有欄位名稱相同時，資料會被覆蓋\n5. 資料內容出現科學記號時，請於Excel中調整儲存格格式，避免資料儲存錯誤\n6. 填入日期時，請以yyyy-mm-dd格式輸入',
    OPPTY_LABEL:'注意事項\n1. 若有填入ID欄位，資料將會更新\n2. 填入Owner、Oppty Contact、Primary Contact欄位時，姓名中間請以空格分開，如：陳 振凱\n3. Oppty Org、Oppty Contact、Primary Contact、Campaign欄位內容不存在時，將自動建立\n4. 自定義欄位名稱若有重複，或與現有欄位名稱相同時，資料會被覆蓋\n5. 資料內容出現科學記號時，請於Excel中調整儲存格格式，避免資料儲存錯誤\n6. 填入日期時，請以yyyy-mm-dd格式輸入\n7. 請勿更改Stage Type，並確認Current Stage填寫正確',
    CASE_LABEL:'注意事項\n1. 若有填入ID欄位，資料將會更新\n2. 填入Owner、Primary Contact欄位時，姓名中間請以空格分開，如：陳 振凱\n3. Primary Contact、Case Org、Campaign、Group、Type欄位內容不存在時，將自動建立\n4. 自定義欄位名稱若有重複，或與現有欄位名稱相同時，資料會被覆蓋\n5. 資料內容出現科學記號時，請於Excel中調整儲存格格式，避免資料儲存錯誤\n6. 填入日期時，請以yyyy-mm-dd格式輸入\n7. 請勿更改Stage Type，並確認Current Stage填寫正確\n8. Case Number欄位未填寫時，將依照流水號設定自動建立',
    KA_LABEL:'注意事項\n1. 若有填入ID欄位，資料將會更新\n2. 填入Owner欄位時，姓名中間請以空格分開，如：陳 振凱\n3. Subject欄位內容不存在時，將自動建立\n4. 資料內容出現科學記號時，請於Excel中調整儲存格格式，避免資料儲存錯誤\n5. Content欄位，請以HTML格式填寫\n6. Article Number欄位未填寫時，將依照流水號設定自動建立',
    COPY:'複製訊息',
    ERROR_2:'請確認匯入檔案內容，或使用範本填寫資料',
    ERROR_3:'因資料筆數較多，系統仍於背景執行匯入程序，若資料尚未完整匯入，請耐心等候並重新整理頁面',
    COPY_2:'已複製',
    SEARCH:'搜尋字串',
    ING:'處理中',
    OPPTY_PRODUCT:'匯出商機產品',
    PROGRESS:'目前進度 : ',
    STILL_RUN:'匯入作業進行中，是否前往"匯入/上傳紀錄"檢視進度?',
  },
  UPCHAT:{
    TITLE:'訊息紀錄',
    SENDER:'寄送者 : ',
    SENDER_B:'寄送者 : 機器人',
    ROBOT:'機',
    NO_MESSAGE:'尚無對話紀錄',
    SHOW:'顯示訊息',
    CHANNEL:'訊息來源',
    BLANK:'請點擊對話以顯示聯絡人資訊',
    NO_AGENT:'未指派客服人員',
    SYNC:'您確定要同步此聯絡人嗎？',
    CIC: 'UpDay 客戶互動中心',
  },
  DOCUMENT:{
    TITLE:'檔案',
    NO_DOC:'尚無檔案',
    NAME:'名稱',
    DESCRIPTION:"描述",
    CREATE:'建立時間',
    UPDATE:'修改時間',
    ADD:'新增檔案',
    EDIT:'修改檔案',
    DEL:'刪除檔案',
    NEW_FILE:'選擇新檔案',
    MESSAGE_DEL:'您確定要刪除此檔案嗎？',
    MESSAGE_DEL_2:'您確定要刪除這些檔案嗎？'
  },
  BULK_EDIT:{
    TITLE:'批量修改',
    CHANGE_STAGE:'更新流程',
    MESSAGE:`確定要更新以下案件嗎？`,
    MESSAGE_CONTACT:'確定要更新以下聯絡人嗎？',
    MESSAGE_OPPTY:'確定要更新以下商機嗎？',
    MESSAGE_ORG:'確定要更新以下公司嗎？',
    MESSAGE_DATA:'確定要更新已選取的 ',
    MESSAGE_DATA_2:' 筆資料嗎？',
    MESSAGE_EMPTY:'請選擇要更新的數據',
    ERROR_CASE:'以下案件已結案，如需批量修改，請將結案原因設為空值。',
    ERROR_DEAL:'以下商機已結案，如需批量修改，請更新結案原因並選擇重啟商機。',
  },
  BULK_EDIT_LIST:{
    0:'維持原數據',
    1:'更新數據',
    2:'設為空值',
  },
  INBOX:{
    SEND:'送出',
    TITLE:'留言板',
    UNREAD:'未讀',
    ALL:'全部',
    POST:'貼文',
    ALERT:'提示',
    FOCUS:'提示貼文',
    NWE_POST:'新增貼文',
    SUBSCRIBE:'訂閱',
    UNSUBSCRIBE:'取消訂閱',
    READ_MORE:'顯示全部內容',
    READ_LESS:'顯示部分內容',
    DELETE:"您確定要刪除此貼文嗎？",
    DELETE_ALERT:"您確定要刪除此提示嗎?",
    CONTENT_EMPTY:"內容空白!",
    CONTENT_NOT_EMPTY:"如果關閉，你將失去已撰寫的內容。 你確定要關閉嗎?",
    FILE_LIMIT:"上傳檔案總量大小不可超過20MB",
    ALL_READ:"標示全部為已讀",
    ALL_DELETE:"刪除全部貼文",
    ALL_DELETE_CHECK:"您確定要刪除您所有的貼文嗎？",
    ALL_DELETE_ALERT:"刪除全部提示",
    ALL_DELETE_ALERT_CHECK:"您確定要刪除所有的提示嗎？",
    ERROR_POST:'該貼文可能被刪除，請重新整理INBOX內容。',
    NO_NOTIFICATION:'暫時沒有新消息，現在可以放鬆一下~',
    ALL_HAVE_READ:'恭喜您，所有消息都已讀取!',
  },
  FOLLOW:{
    CONTACT:"追蹤或取消追蹤所選聯絡人",
    ORG:"追蹤或取消追蹤所選公司",
    CASE:"追蹤或取消追蹤所選案件",
    OPPTY:"追蹤或取消追蹤所選商機",
    FOLLOW:'追蹤',
  },
  UPLOADSTATUS:{
    METHOD:'方式',
    TYPE:'類型',
    CREATE_TIME:"建立時間",
    UPDATED_TIME:"更新時間",
    FILE_NAME:"檔案名稱",
    TOTAL:"總計",
    SUCCESS:"成功",
    FAIL:"失敗",
    STATUS:"狀態",
    COMMENT:"紀錄",
    UPLOAD:'上傳',
    IMPORT:'匯入',
    PROCESSING:"處理中",
    COMPLETE:"完成",
    EMBEDDING:"分析中",
    SHOW:'顯示紀錄',
    NO_RECORD:'尚無紀錄',
    MESSAGE_DELETE:'您確定要刪除紀錄嗎？',
  },
  AL_MAIL:{
    TITLE:'AI撰寫助手',
    GENERATE:"AI訊息生成",
    SETTING:"AI生成設定",
    SEND_ALL:"全部送出",
    TONE:"語調",
    LENGTH:"長度",
    LANGUAGE:"語言",
    TIP:"提示",
    SIGN:"簽名檔",
    REMAKE:"重新生成",
    REMAKEING:"生成中...",
    NO_AMIL:"尚無AI訊息",
    SENDING:"寄送中...",
    FOLLOWUP: "回復查詢",
    SUMMARY: "提出摘要",
    CUSTOM: "自訂",
    TONE_1:"專業",
    TONE_2:"放鬆",
    TONE_3:"刺激",
    TONE_4:"有趣",
    TONE_5:"熱情",
    LEN_1:"簡短",
    LEN_2:"中等",
    LEN_3:"詳細",
    STATUS_1:"尚無訊息",
    STATUS_2:"已生成",
    STATUS_3:"已儲存",
    STATUS_4:"尚未儲存",
    STATUS_5:"失敗",
    CHANGE_ERROR:"更改推播渠道或客戶分群將會刪除已生成的訊息。",
    UNDO:"復原",
    REMAKE_ALL:"您確定要刪除現有資料，並以目前設定生成訊息嗎?",
    SEND_CHECK:"您確定要送出全部訊息嗎?<br>完成後將刪除現有訊息、設定。",
    MAKE_MSG:"訊息生成中，請耐心等候。<br>點擊更新可確認目前進度。",
    SMS_VENDOR:"請將SMS服務商設定為'三竹簡訊'。若未開啟此服務，請洽詢UpDay服務團隊。",
    NO_CONTACT:"尚無可用聯絡人"
  },
  // GPT----------------------------------------------------------------
  GPT_MENU:{
    DASHBOARD:"儀錶板",
    KNOWLEDGE:"知識庫",
    CHATBOT:"機器人",
    CHAT_HISTORY:"交談紀錄",
    USER_MANAGEMENT:"用戶管理",
    USAGE_ANALYSIS:"數據分析",
    UPLOADSTATUS:'上傳紀錄',
    BACKTO:"回到",
    MEETINGRECAP:"會議記錄",
    AINOTEBOOK:"AI筆記本",
  },
  GPT_KNOWLEDGE:{
    TITLE:"知識庫總覽",
    NAME : "知識庫名稱",
    DOCS: "文件總數",
    CREATED_ON: "建立時間",
    UPDATED_ON: "更新時間",
    UPDATED_BY: "更新者",
    ROBOTS: "機器人使用數",
    BACKTO:"回到",
    LAST_UPDATED:"最後更新",
    ONOFF:"開啟/關閉 知識庫",
    LINKBOT:"連結機器人",
    NO_LINKBOT:"尚未連結",
    CLEAR:"清空",
    CLEAR_ALL:"清空知識庫",
    DELETE:'刪除知識庫',
    DEL_MSG:"您確定要刪除此知識庫嗎?",
    CLEAR_MSG:"您確定要清空此知識庫嗎?",
    SETTING:"知識庫設定",
    UPLOAD:"知識庫檔案上傳",
    ERROR:"此知識庫名稱已存在",
    PUBLIC:"共用知識庫",
    ISPUBLIC:"已共用",
    ADD_COLUMN:"新增欄位",
    EDIT_COLUMN:"修改欄位",
    CLEAR_ERROR:"知識庫檔案清空中，請稍後重新整理畫面。",
    DELETE_ERROR:"知識庫檔案清空後將進行刪除，請稍後重新整理畫面。",
    SINGAL_DELETE:"您確定要刪除此檔案嗎?",
    DELETEING:"檔案刪除中，請稍後重新整理此頁面。",
    IMAGE_SELECTION: "圖片處理選項",
    TIP_1:"將用以下檔案建立知識庫，AI機器人會以此知識庫內容回答問題。",
    TIP_2:"在此上傳Q&A檔案，檔案需為csv格式且內容使用UTF-8編碼。",
    TIP_3:"在此上傳壓縮檔，檔案需為zip格式，請直接將檔案壓縮，不需另外放入資料夾。",
    TIP_4:"在此上傳txt、pdf、docx、pptx檔案，如需上傳csv檔，請至Q&A資料上傳。",
    TIP_5:"在此新增需要分析的網站，最多可新增5個網站，深度最多為2，新增完成後請按'開始分析'。",
    TIP_6:"在此上傳JPG、JPEG、PNG 格式圖片。",
  },
  ADD_KNOWLEDGE:{
    TITLE:'新增知識庫',
    NAME:'名稱',
    DESCRIPTION:'描述',
    NAME_HINT:'知識庫建立後，名稱將不能修改。',
  },
  GPT_UPLOADIMG:{
    DESCRIBE_IMG: '圖像理解',
    CUSTOMER_ATTRIBUTE: '客製屬性',
    IMG_SEARCH: '以圖搜圖',
    TIP_1:"AI會對圖像進行詳細描述，用戶可以根據它提出問題並蒐索答案。",
    TIP_2:"AI 將從圖像中提取屬性並保存到表格中。如果您希望 AI 回答像「亞洲最昂貴的前三項物品」這樣的問題，這將非常有用。請聯繫我們以啟用此功能。",
    TIP_3:"AI會分析使用者傳送的影像，並為使用者找到相似的影像。對於產品搜尋等場景很有用。",
    FILEUPLOADMESSAGE: '僅接受 JPG、JPEG、PNG 檔',
    START_ANALYSIS: '開始分析',
    IMAGE_STATUS: '圖片狀態',
  },
  GPT_CLOUD:{
    CONNECT_CLOUD: '連結雲端',
    CONNECT_ACCOUNT: '連結帳號',
    RESET: '重置',
    SAVE: '儲存',
    DELETE_CHECK: '您確定要刪除此雲端帳號設定嗎?',
    CLOUDSYNC_ERROR: '刪除失敗，請重試',
    MY_DRIVE: '我的檔案',
    SHARE_WITH_ME: '已共用',
    SHAREPOINT: 'Sharepoint',
    EXCEED_MAX_SIZE: '上傳檔案總量大小超過',
  },
  GPT_ANALYSIS:{
    NAME : "數據分析",
    USED_TOKEN:"消耗 Token",
    MSG_TOKEN:"平均每訊息消耗 Token",
    MSG_Q:"訊息量",
    MSG_PER_Q:"平均花費 Token",
    ACU:"累積消耗 Token",
    THIS_PERIOD:'本期',
    PREVIOUS:'上期',
    TABLE_HEADER_1:"智能機器人",
    TABLE_HEADER_2:"使用類型",
    TABLE_HEADER_3:"Prompt Tokens",
    TABLE_HEADER_4:"Completion Tokens",
    TABLE_HEADER_5:"費用",
    TABLE_HEADER_6:"時間",
  },
  GPT_AIBOTS:{
    TITLE:"機器人總覽",
    AIASSISTANTNAME: 'AI助理名稱',
    AIASSISTANTNAME_TIP1: '設定您AI助理的名稱',
    AIASSISTANTNAME_TIP2: '範例: 小安',
    AIASSISTANTROLE: 'AI助理角色',
    AIASSISTANTROLE_TIP1: '設定您AI助理所扮演的角色',
    AIASSISTANTROLE_TIP2: '範例: customer service agent (客服人員) for UpDay, a CRM production designed by 安然科技股份有限公司',
    TOPIC: '主題',
    TOPIC_TIP1: '提供AI助理一個主題，使其能更好的改寫提問',
    TOPIC_TIP2: '範例: UpDay CRM',
    TOPICLIMIT: '主題限制',
    TOPICLIMIT_TIP1: '如果問題超出主題,AI助理會回到應有的主題範圍回覆',
    TOPICLIMIT_TIP2: '範例: technology',
    REPLY_LANGUAGE: '回覆語言',
    REPLY_LANGUAGE_TIP: 'AI助理會依據所選擇的語言回覆',
    MODEL:'AI模型',
    MEMORY_LENGTH:'機器人記憶長度',
    RETURN_KNOWLEDGE:'使用知識數量',
    INBOX:"收件匣",
    HAS_AGENT_BOT:"此收件匣已連結其他機器人。",
    NO_AGENT_BOT:"尚無UpChat機器人，請聯繫UpGPT客服進行設置。",
  },
  ADD_AIBOTS:{
    TITLE:'新增機器人',
    NAME:'名稱',
    DESCRIPTION:'描述',
    INBOX:"收件匣",
    KNOWLEDGE:"知識庫",
    MEMORY_LENGTH:'記憶長度',
    RETURN_KNOWLEDGE:'使用知識量',
    IMAGE_SEARCH: '允許圖片搜尋',
    RETURN_IMAGE_QUANTITY: '回傳圖片數量',
    ROLE:"角色",
    SCOPE:"回覆限制",
    TOPIC:"主題",
    ONOFF:"開啟/關閉 機器人",
    DETAIL:"機器人詳細資料",
    BRAIN_SIZE:"知識儲備",
    C_SETTING:"角色設定",
    DEL_MSG:"您確定要刪除此機器人嗎?",
    M_1:"微調知識設定。",
    M_2:"機器人將根據提供的知識庫進行回應。",
    M_3:"設定愈多的記憶體長度會參考愈多的上下文，但回應速度會減慢。\n設定愈多的使用知識量，將提供更精確的回應，但速度會減慢。",
    M_4:"機器人會根據使用者的角色設定做出反應和回應。",
    M_5:"角色會影響回應的語氣和態度。",
    M_6:"如果問題超出主題，機器人會回到應有的主題範圍回覆。",
    M_7:"提供機器人一個主題，使其能更好的改寫提問。",
    M_8:"機器人的自稱。",
  },
  GPT_UPLOAD:{
    DEL_K:"已刪除的知識庫",
    AutoSync:"雲端自動同步",
    ManualSync:"雲端手動同步",
    AutoKnow:"連結的知識庫"
  },
  GPT_USER:{
    T_1:"個人資訊",
    T_2:"變更密碼",
  },
  GPT_CHAT_HISTORY:{
    CHANNEL:"渠道",
    C_USER_NAME:"使用者名稱",
    LAST_CHAT_TIME:"最後對話時間",
    L_SELECT:"請從左側選擇一個對話",
    SEARCH:"搜尋訊息",
  },
  GPT_MEETINGRECAPS:{
    TITLE:"會議記總覽",
    NAME:"名稱",
    participants:"參與者",
    OWNER:"擁有者",
    TIME:"時間",
    DELETE:"您確定要刪除此會議紀錄嗎？"
  },
  GPT_MEETINGRECAP:{
    TITLE:"會議記錄",
    NAME:"會議名稱",
    MS:"會議總結",
    OWNER:"擁有者",
    MS_TIME:"會議時間",
    ANR:"關聯AI記事本",
    AN:"AI 記事本",
    OPPTY:"商機",
    ANs:"AI筆記",
    TS:"文字紀錄",
    ANALYZE: "AI分析",
    SUGGEST_TASKS:"建議任務",
    CREATE_TASKS:"創建任務",
    VIEW_TASKS:"檢視任務",
    SUMMARY:"生成總結",
    VALIDATE:"請確認必填欄位，並綁定商機。",
    NO_T:"沒有文字紀錄。",
    DELETED_MEETING_RECAP:"此會議記錄已被刪除，請重新整理頁面",
    EXIST_SUMMARY_AND_TASK:"會議總結及任務已被生成，請重新整理頁面並確認內容",
    MAIL_SETTING:"信件設定確認中",
    MAIL_GENERATE:"AI 信件生成中",
    EMPTY_SUMMARY:"AI 無法從空白的會議總結中生成合適的email",
    EMPTY_CUSTOM_PROMPT:"AI 無法以空白的prompt生成合適的email",
    EMPTY_SUMMARY_TASK:"AI 無法從空白的會議總結中生成合適的任務",
    TASK_CANT_FIND_ACTIVITY:"相關的活動已被刪除，請再次創建新的任務",
  },
  GPT_AINOTEBOOK:{
    TITLE:"AI筆記本總覽",
    LAST_UPDATED:"最後更新",
    DESCRIPTION:'描述',
    RECORD_TYPE:'紀錄類型',
    DELETE:"您確定要刪除此AI筆記本嗎？",
    DELETE_ERROR:"至少需有一個AI筆記本",
    DEFAULT:"系統預設",
    CRM_TIP:"選擇您希望小安在會議中自動為您擷取的 CRM 資料。",
    AI_NOTE_TIP:"自定義您希望小安在會議中自動為您擷取的資料。",
  },
  ADD_AINOTEBOOK:{
    TITLE:'新增AI筆記本',
    EDIT:'編輯AI筆記本',
    ADD: '新增欄位',
    COLUMN: '欄位',
    NAME:'名稱',
    DESCRIPTION:'描述',
    DELETE_ERROR:"您確定要刪除此欄位嗎？",
    NAME_HINT:'AI筆記本建立後，名稱將不能修改。',
    COLUMN_NAME_HINT:'欄位建立後，名稱將不能修改。',
    LIMIT_HINT:'欄位描述限制50字。',
    LIMIT_COLUMNS:'欄位數量已達上限。',
    CRM_SYSTEM_FIELD: 'CRM欄位',
  },
  //Up Meeting
  UPMEETING:{
    LOGIN:"UpSide AI助理",
    ANN:"小安",
    CHANGE_AN:"更換AI記事本將會清除已紀錄的資訊。",
    NOTE_C:"個欄位已完成填寫。",
    MEETING_OVER:"您的會議已結束，點擊以下連結查看紀錄：",
    L_D_TITLE:"是否保留上次參加的會議?",
    L_D_LAST:"繼續之前的會議",
    L_D_NEW:"開始新的會議",
    FINISH:"結束",
    CAPTION_ERROR:"請先開啟Teams或Google會議。",
    IMAGE_PREFIX:"AI問題分析中...",
    QUERY_PREFIX:"AI關鍵字生成中...",
    SEARCH_PREFIX:"AI資訊分析中...",
    IMAGE_ERROR:"圖片分析失敗，請在試一次。",
    BACKEND_ERROR:"系統設定有誤，請聯繫系統管理員。",
    SHOT_BTN_TITLE:"AI資訊分析",
    NO_QA:"請點擊AI圖示或輸入關鍵字進行搜尋。",
    Problem_Analysis:"問題分析",
    Causes_Analysis:"原因分析",
    Solutions:"解決方案",
    Actions:"行動",
    References:"參考資料",
    OutlookError:"沒有開啟的郵件，或是設定不符",
  },
  //Other
  BUSINESS_TYPE:{
    OPPTY:"商機",
    CASE:"案件",
    COMPANY:"公司",
    CONTACT:"聯絡人",
    AI_NOTEBOOK:"AI筆記本",
  }
}
